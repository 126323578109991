import { AuAuthorizationGuard } from '../../../authorization/au-authorization.guard';

import { MenuItem } from '../models/menu-item.model';

import { routePathName } from '../../../shared/constants/route-pathname.constant';

import { PermissionType } from '../../../shared/services/http-services.service';

export class MenuFactory {
    private static employeesPrefix: string = `/${routePathName.employee}`;

    public static get menu(): MenuItem[] {
        return [
          this.overviewMenu(),
          this.authorizationsMenu(),
          this.generalInformationMenu(),
          this.profileMenu()
        ];
    }

    private static overviewMenu(): MenuItem {
        return new MenuItem(routePathName.overview, 'OVERVIEW._', `${this.employeesPrefix}/${routePathName.overview}`, [
          new MenuItem(routePathName.overviewTimeline, 'OVERVIEW.TIMELINE', routePathName.overviewTimeline),
          new MenuItem( routePathName.overviewPlanningAndTasks, 'OVERVIEW.PLANNING', routePathName.overviewPlanningAndTasks).guardedBy(this.createGuard([
            PermissionType[PermissionType.CanReadClientTasks]
          ])),
          new MenuItem(routePathName.overviewDocuments, 'OVERVIEW.DOCUMENTS', routePathName.overviewDocuments).guardedBy(this.createGuard([
            PermissionType[PermissionType.CanReadClientDocuments]
          ])),
        ]);
    }

    private static authorizationsMenu(): MenuItem {
        return new MenuItem(routePathName.authorizations, 'AUTHORIZATIONS._', `${this.employeesPrefix}/${routePathName.authorizations}`, [])
          .guardedBy(this.createGuard([ PermissionType[PermissionType.CanReadClientEmpowerment] ]));
    }

    private static generalInformationMenu(): MenuItem {
        return new MenuItem(routePathName.generalInformation, 'GENERAL_INFORMATION._', `${this.employeesPrefix}/${routePathName.generalInformation}`, [])
          .guardedBy(this.createGuard([ PermissionType[PermissionType.CanReadClientGeneralInformation] ]));
    }

    private static profileMenu(): MenuItem {
        return new MenuItem(routePathName.profile, 'PROFILE._', `${this.employeesPrefix}/${routePathName.profile}`, []);
    }

    private static createGuard(permissions: string[]): AuAuthorizationGuard {
        return new AuAuthorizationGuard().requiresPermissions(permissions);
    }
}

