<au-page>
  <au-page-banner image="/assets/images/landing-banner-lg.jpg"
                  imageSet="/assets/images/landing-banner-sm.jpg 600w,
                            /assets/images/landing-banner-lg.jpg 1440w"
                  landing>
    <h1 mdcHeadline3>{{ 'LANDING.BANNER_TITLE' | translate }}</h1>
  </au-page-banner>
  <au-page-content noTopbar landing mdcBody1>

    <au-page-content-section fixedFooter>
      <div mdcHeadline5>
        {{ 'LANDING.TITLE' | translate }}
      </div>
      <div class="page-content__container" [innerHTML]="'LANDING.MESSAGE' | translate "></div>
      <au-choose-language></au-choose-language>
    </au-page-content-section>

    <ng-container *ngIf="isBackendOnline$ | withLoading | async as isBackendOnline">
      <au-page-content-footer fixedFooter>
        <au-button *ngIf="isBackendOnline.value" (click)="logIn()">{{ 'LOGIN' | translate }}</au-button>
      </au-page-content-footer>
    </ng-container>

  </au-page-content>
</au-page>
