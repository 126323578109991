import { Injectable } from '@angular/core';
import { Language } from './http-services.service';
import { ContentService, ContentItem } from './content.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivacyStatementService {

  constructor(private contentService: ContentService,
    private storageService: StorageService) {
  }

  public get htmlContent(): Observable<ContentItem> {
    const language = this.storageService.language;
    const htmlContentName = this.getHtmlContentName(language);
    return this.contentService.getContent(`privacy/${htmlContentName}`, language);
  }

  public get documentUrl(): string {
    const language = this.storageService.language;
    const documentName = this.getDocumentName(language);
    return this.contentService.getDocumentUrl(documentName, language);
  }

  private getHtmlContentName(language: Language): string {
    switch (language) {
      case Language.Dutch:
        return 'Mijn Arbo Unie gebruikersvoorwaarden en privacyverklaring.htm';
      case Language.English:
        return 'Mijn Arbo Unie conditions for use and privacy statement.htm';
    }
  }

  private getDocumentName(language: Language): string {
    switch (language) {
      case Language.Dutch:
        return 'Mijn Arbo Unie gebruikersvoorwaarden en privacyverklaring.pdf';
      case Language.English:
        return 'Mijn Arbo Unie conditions for use and privacy statement.pdf';
    }
  }
}
