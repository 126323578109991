<div style="padding-top:1px;margin:16px 0">
  <!--<mdc-list-group subheader="{{ 'CHOOSE_LANGUAGE.TITLE' | translate }}">
    <form [formGroup]="languageForm">
      <au-select controlName="language" [items]="languageItems"></au-select>
    </form>
  </mdc-list-group>-->
  <div class="language-form-title">{{ 'CHOOSE_LANGUAGE.TITLE' | translate }}</div>
  <form [formGroup]="languageForm">
    <au-select controlName="language" [items]="languageItems"></au-select>
  </form>
</div>
