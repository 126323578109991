<ng-container *ngIf="!isIframe">
  <ng-container *ngIf="!isBooting; else booting">
    <au-topbar></au-topbar>
    <au-panel>
      <router-outlet></router-outlet>
    </au-panel>
    <au-desktop-footer></au-desktop-footer>
  </ng-container>
</ng-container>

<ng-template #booting>
  <mdc-linear-progress></mdc-linear-progress>
</ng-template>

