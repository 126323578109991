import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavigationService } from '../shared/services/navigation.service';
import { StorageService } from '../shared/services/storage.service';
import { RegistrationStateService, RegistrationType } from '../registration/registration-state.service';
import { registerRoutePathName } from '../registration/registration-routing.constants';
import { environment } from '../../environments/environment';
import { Subscription, Subject, Observable, interval } from 'rxjs';
import { InteractionRequiredAuthError, AuthResponse, AuthenticationParameters, AuthError } from 'msal';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { MsalService } from '@azure/msal-angular';
import { routePathName } from '../shared/constants/route-pathname.constant';


export const authState = {
  signIn: 'signIn',
  signUp: 'signUp',
  getAccessToken: 'getAccessToken',
  passwordReset: 'passwordReset',
  passwordResetByProfile: 'passwordResetByProfile'
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private passwordResetCode = 'AADB2C90118';
  private cancelledCode = 'AADB2C90091';
  private tokenExpirationSubscription: Subscription = new Subscription();
  private clearAuthenticationSubject = new Subject<any>();

  private jwtHelper = new JwtHelperService(); // Note: do not inject this instance because just using the helper functions

  public onAccessTokenExpired: EventEmitter<any> = new EventEmitter();

  constructor(
    private navigationService: NavigationService,
    private registrationStateService: RegistrationStateService,
    private storageService: StorageService,
    private authService: MsalService) {
    this.initializeTokenExpirationEvent();
  }

  public logIn(): void {
    this.storageService.clearUserInformation();
    const loginRequest: AuthenticationParameters = {
      state: authState.signIn,
      scopes: environment.azureSettings.b2cScopes,
      prompt: 'login',
      extraQueryParameters: { ui_locales: this.storageService.language }
    };

    this.setUserAgentApplicationPolicy(environment.azureSettings.signInPolicyId);
    this.authService.loginRedirect(loginRequest);
  }

  public passwordReset(byProfile?: boolean, stateContext?: string): void {
    let state = !!byProfile ? authState.passwordResetByProfile : authState.passwordReset;
    if (stateContext) {
      if (stateContext.indexOf(':') !== -1) {
        const accountStateParts = stateContext.split(':');
        const registrationType = accountStateParts[1];
        const registrationKey = accountStateParts[2];
        state = `${state}:${registrationType}:${registrationKey}`;
      }
    }

    const loginRequest: AuthenticationParameters = {
      state,
      scopes: environment.azureSettings.b2cScopes,
      prompt: 'login',
      extraQueryParameters: { ui_locales: this.storageService.language }
    };

    this.setUserAgentApplicationPolicy(environment.azureSettings.passwordResetPolicyId);
    this.authService.loginRedirect(loginRequest);
  }

  public signUp(type: RegistrationType, key: string): void {
    this.storageService.clearUserInformation();
    const loginRequest: AuthenticationParameters = {
      state: this.createRegistrationState(authState.signUp, key, type),
      scopes: environment.azureSettings.b2cScopes,
      prompt: 'login',
      extraQueryParameters: { ui_locales: this.storageService.language }
    };

    this.setUserAgentApplicationPolicy(environment.azureSettings.signUpSignInPolicyId);
    this.authService.loginRedirect(loginRequest);
  }

  public signIn(type: RegistrationType, key: string): void {
    this.storageService.clearUserInformation();
    const loginRequest: AuthenticationParameters = {
      state: this.createRegistrationState(authState.signIn, key, type),
      scopes: environment.azureSettings.b2cScopes,
      prompt: 'login',
      extraQueryParameters: { ui_locales: this.storageService.language }
    };

    this.setUserAgentApplicationPolicy(environment.azureSettings.signInPolicyId);
    this.authService.loginRedirect(loginRequest);
  }

  public logOut(): void {
    this.clearAuthentication();
    this.authService.logout();
  }

  public clearAuthentication(): void {
    this.storageService.clearUserInformation();
    this.clearAuthenticationSubject.next();
  }

  public getUserRoleAndEmail(): string {
    return `${this.storageService.userRole};${this.storageService.userEmail}`;
  }

  public async getAccessToken(): Promise<string> {
    if (this.isAuthenticated() && this.storageService.authenticationState) {
      // The policy is retrieved from the current authentication state.
      // This has to be done in order to validate the token cache in MSAL since we use multiple 'signIn' flows.
      const policy = this.getPolicyFromState(this.storageService.authenticationState);
      if (policy)
        this.setUserAgentApplicationPolicy(policy);

      let state = authState.getAccessToken;

      if (this.registrationStateService.key !== undefined && this.registrationStateService.type !== undefined)
        state = this.createRegistrationState(state, this.registrationStateService.key, this.registrationStateService.type);

      const accessTokenRequest: AuthenticationParameters = {
        scopes: environment.azureSettings.b2cScopes,
        state
      };

      // Try to get the access token silently through an IFrame
      return this.authService.acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: AuthResponse) => {
          this.storageService.authenticationState = accessTokenResponse.accountState;
          return accessTokenResponse.accessToken;
        })
        .catch(error => {
          // This error happens when the user blocked cookies, meaning it cannot retrieve the current login status.
          if (error instanceof InteractionRequiredAuthError && error.errorCode === 'interaction_required') {

            // Fall back to redirecting the user to Azure to retrieve the access token.
            // This comes back using the tokenReceivedCallback method.
            this.authService.acquireTokenRedirect(accessTokenRequest);
          }

          return null;
        });
    }

    return Promise.resolve(null);
  }

  public isAuthenticated(): boolean {
    return this.storageService.idToken !== null && !this.jwtHelper.isTokenExpired(this.storageService.idToken);
  }

  public hasAuthenticationState(): boolean {
    return !!this.storageService.authenticationState;
  }

  public onClearAuthentication(): Observable<any> {
    return this.clearAuthenticationSubject.asObservable();
  }

  // This callback is called after a redirect happened
  public tokenReceivedCallback = (error: AuthError, response?: AuthResponse): void => {
    if (error) {
      this.errorReceivedCallback(error, response.accountState);
    } else {
      if (response.tokenType === 'access_token')
        // This is the case when the user disabled third party cookies & disabled popups.
        // The user gets his access token through a redirect
        this.accessTokenReceivedCallback(response);
      else
        // This callback is called when the user logs in
        this.idTokenReceivedCallback(response);
    }
  }

  private accessTokenReceivedCallback(response: AuthResponse) {
    const state = response.accountState;
    this.storageService.authenticationState = state;

    const token = response.idToken.rawIdToken;
    this.storeIdTokenState(authState.getAccessToken, state, token, response.idTokenClaims);
  }

  private idTokenReceivedCallback(response: AuthResponse) {
    const state = response.accountState;
    this.storageService.authenticationState = state;

    const token = response.idToken.rawIdToken;
    // Token received from password reset or login action.
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);

      if (decodedToken.tfp.toLowerCase() === environment.azureSettings.passwordResetPolicyId.toLowerCase()) {
        this.navigateAfterPasswordReset(state);
      } else {
        this.storeIdTokenState(this.getSanitizedAuthenticationState(state), state, token, response.idTokenClaims);
      }
    }
  }

  private storeIdTokenState(authenticationState: string, accountState: string, idToken: string, idTokenClaims: StringDict): void {
    // This only happens when the user came in through the registration flow
    if (accountState.startsWith(`${authenticationState}:`)) {
      const accountStateParts = accountState.split(':');
      const type = accountStateParts[1];
      const key = accountStateParts[2];
      const emails = idTokenClaims.emails; //array

      // idTokenClaims are defined as a StringDict but if filled the actual type is used and not a string so cast to boolean to override the type (sic)
      const newUser = idTokenClaims.newUser as unknown as boolean;
      this.registrationStateService.storeSignUpData(type, key, newUser, emails[0]);
    }
    this.storeIdToken(idToken);
  }

  private errorReceivedCallback = (errorDesc: AuthError, state: string): void => {
    // Some errorcode received, either real or indicating that password reset was clicked.
    if (errorDesc.errorMessage.startsWith(this.passwordResetCode)) {
      // User clicked forgot password link, redirect tot password reset page.
      this.passwordReset(false, state);
    } else if (errorDesc.errorMessage.startsWith(this.cancelledCode)) {
      // User clicked cancel in signin, signup or password reset
      this.navigateAfterCancel(state);
    } else {
      // A real error occurred, return to landing page.
      this.navigationService.goToLanding();
    }
  }

  private setUserAgentApplicationPolicy(policy: string): void {
    this.authService.authority = environment.azureSettings.authority + policy;
  }

  private getPolicyFromState(state: string): string | undefined {
    state = this.getSanitizedAuthenticationState(state);

    switch (state) {
      case (authState.signIn):
        return environment.azureSettings.signInPolicyId;
      case (authState.signUp):
        return environment.azureSettings.signUpSignInPolicyId;
      case (authState.passwordReset):
        return environment.azureSettings.passwordResetPolicyId;
      case (authState.getAccessToken):
      default:
        return undefined;
    }
  }

  private navigateAfterCancel(state: string): void {
    if (state) {
      // if state consists of multiple parts then there a registration is in progress and navigate to registration landing page
      if (state.indexOf(':') !== -1) {
        this.navigateToRegistrationLanding(state);
        return;
      }

      if (state === authState.passwordResetByProfile) {
        this.navigationService.setUrl(`/${routePathName.employee}/${routePathName.profile}`);
        return;
      }

      if (state === authState.signIn || state === authState.passwordReset) {
        // If cancel/error occurred during signin flow and password reset is called in signin flow then start login again
        this.logIn();
        return;
      }
    }

    this.navigationService.goToLanding();
  }

  private navigateAfterPasswordReset(state: string): void {
    if (state) {
      // if state consists of multiple parts then there a registration is in progress and navigate to registration landing page
      if (state.indexOf(':') !== -1) {
        this.clearAuthentication();
        this.navigateToRegistrationLanding(state);
      }
      else {
        this.logOut();
      }
    }
  }

  private navigateToRegistrationLanding(state: string): void {
    if (state) {
      // if state consists of multiple parts then there a registration is in progress and navigate to registration landing page
      if (state.indexOf(':') !== -1) {
        const accountStateParts = state.split(':');
        const registrationType = accountStateParts[1];
        const registrationKey = accountStateParts[2];
        const registerRoute = RegistrationType[registrationType] === RegistrationType.Employee ? registerRoutePathName.employee : registerRoutePathName.employer;

        this.navigationService.setUrl(`/${routePathName.registration}/${registerRoute}/${registrationKey}`);
        return;
      }
    }

    this.navigationService.goToLanding();
  }

  private getSanitizedAuthenticationState(state: string): string {
    // Sanitize some of the authentication states because the employeekey is attached to it for preserving state
    if (state) {
      if (state.startsWith(`${authState.signUp}:`))
        return authState.signUp;
      else if (state.startsWith(`${authState.signIn}:`))
        return authState.signIn;
      else if (state.startsWith(`${authState.getAccessToken}:`))
        return authState.getAccessToken;
    }

    return state;
  }

  private createRegistrationState(state: string, key: string, type: RegistrationType) {
    return `${state}:${RegistrationType[type]}:${key}`;
  }

  private storeIdToken(token: string): void {
    this.storageService.idToken = token;
    this.initializeTokenExpirationEvent();
  }

  private initializeTokenExpirationEvent(): void {
    const millisecondsUntilExpiration: number = this.getMillisecondsUntilTokenExpires();

    if (millisecondsUntilExpiration <= 0) {
      return;
    }

    this.tokenExpirationSubscription.unsubscribe();
    this.tokenExpirationSubscription = interval(millisecondsUntilExpiration)
      .subscribe(() => {
        this.onAccessTokenExpired.emit();
        this.clearUserSession();
        this.tokenExpirationSubscription.unsubscribe();
      });
  }

  private clearUserSession(): void {
    this.logOut();
    this.navigationService.goToLanding();
  }

  private getMillisecondsUntilTokenExpires(): number {
    const tokenValidityInMilliseconds: number = this.storageService.idToken !== null
      ? this.jwtHelper.getTokenExpirationDate(this.storageService.idToken).getTime()
      : 0;

    return tokenValidityInMilliseconds - new Date().getTime();
  }
}
