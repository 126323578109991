import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiException } from './http-services.service';
import { JL } from 'jsnlog';

@Injectable({
  providedIn: 'root'
})
export class UncaughtExceptionService implements ErrorHandler {
    handleError(error: any) {
      console.error(error);

      // Only log the clienterrors. The server errors are logged by the server-errors.httpinterceptor
      if (!(error instanceof HttpErrorResponse) && !(error instanceof ApiException)) {
        JL('ClientError').error(error.message ? error.message : error.toString());
      } 
    }
}
