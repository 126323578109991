<au-page>
  <au-page-content noBanner>
    <au-page-content-topbar title="Tessie"></au-page-content-topbar>
    <au-page-content-section>
      <h1 mdcHeadline4>Tessie</h1>
      <div mdcBody1>

        <button class="main-button">
          AU
        </button>
        &nbsp;
        <button mdcRipple [attachTo]="button" class="main-button" #button>
          AU + ripple
        </button>
        &nbsp;
        <button mdc-button class="main-button">
          AU en material
        </button>
        &nbsp;
        <button mdc-button raised class="button--main-action custom">
          Button met material
          <!--<mdc-button-label>Button met material</mdc-button-label>
  <mdc-icon>arrow_forward</mdc-icon>-->
        </button>

      </div>
    </au-page-content-section>
    <au-page-content-footer>
      <mdc-tab-bar class="page-content__tabbar custom-tabbar"  useAutomaticActivation alignStartFooter>
        <mdc-tab-scroller>
          <mdc-tab class="aap">Engels</mdc-tab>
          <mdc-tab>Nederlands</mdc-tab>
        </mdc-tab-scroller>
      </mdc-tab-bar>
      <button mdc-button raised class="button--main-action">
        <mdc-button-label>Gogo gadget</mdc-button-label>
        <mdc-icon>arrow_forward</mdc-icon>
      </button>
    </au-page-content-footer>
  </au-page-content>
</au-page>
