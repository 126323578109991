import { Component, OnInit } from '@angular/core';
import { VersionClientService, ComponentVersionData } from '../shared/services/http-services.service';


@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  componentVersionData : ComponentVersionData;

  constructor(private versionClientService: VersionClientService) { }

  ngOnInit(): void {
    this.versionClientService.getVersion().subscribe(componentVersionData =>
      this.componentVersionData = componentVersionData);
  }
}
