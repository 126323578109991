import { Component } from '@angular/core';

@Component({
    selector: 'au-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
    constructor() {}
}
