<mdc-top-app-bar class="page-content__topbar">
  <mdc-top-app-bar-row>
    <mdc-top-app-bar-section align="start" [title]="title">
      <button *ngIf="showBackButton || customBackAction" mdcTopAppBarNavIcon (click)="handleBackClick()">
        <mdc-icon>navigate_before</mdc-icon>
      </button>
      <ng-content></ng-content>
    </mdc-top-app-bar-section>
  </mdc-top-app-bar-row>
</mdc-top-app-bar>

