import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { APP_INITIALIZER, NgModule, Injector } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService, MissingTranslationHandler, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StorageService } from './services/storage.service';
import { MissingTranslationHelper } from './helpers/missingtranslation.helper';
import { CustomTranslateCompiler } from './helpers/custom-translate-compiler.helper';
import { LOCATION_INITIALIZED } from '@angular/common';
import 'dayjs/locale/nl'; // en is include by default
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translateService: TranslateService, storage: StorageService, injector: Injector): () => Promise<any> {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      // this language will be used as a fallback when a translation isn't found in the current language
      translateService.setDefaultLang('default');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translateService.use(storage.language).subscribe(() => {
      }, err => {
        console.error(`Problem with initializing language '${storage.language}'`);
      }, () => {
        // initialize the dayjs library, not the right place but here is the current language known
        dayjs.extend(utc);
        dayjs.extend(customParseFormat);
        dayjs.locale(storage.language);
        resolve(null);
      });
    });
  });
}

@NgModule({
  imports: [
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
      useDefaultLang: false
    })
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, StorageService, Injector],
      multi: true
    }
  ]
})
export class MultiLanguageModule {
  public static forRoot(): ModuleWithProviders {
    return TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: CustomTranslateCompiler
      }
    });
  }
}
