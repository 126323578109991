import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MdcDrawer } from '../../shared/angular-mdc/drawer/drawer';
import { LayoutService } from '../layout.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { StorageService } from '../../shared/services/storage.service';
import { BaseMenuComponent } from './abstract-components/base-menu.component';
import { MenuService } from './services/menu.service';
import { LanguageService } from '../../shared/services/language.service';
import { PrivacyStatementService } from '../../shared/services/privacy-statement.service';

@Component({
  selector: 'au-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent extends BaseMenuComponent {
  @ViewChild('mdcDrawer', {static: false}) mdcDrawer: MdcDrawer;
  menuClass = 'menu menu-hidden';
  private windowWidth = 0;

  constructor(
    public router: Router,                // for use in template
    private layoutService: LayoutService,
    private privacyStatementService: PrivacyStatementService,
    private ref: ChangeDetectorRef,
    authenticationService: AuthenticationService,
    storageService: StorageService,
    menuService: MenuService,
    languageService: LanguageService) {

    super(authenticationService, storageService, menuService, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.layoutService.onToggleMenu().subscribe(() => {
      this.menuClass = 'menu';
      this.mdcDrawer.open = !this.mdcDrawer.open;
    });

    this.layoutService.onResize().subscribe(size => {
      if (this.windowWidth !== size.width) {
        this.windowWidth = size.width;
        this.ref.markForCheck();
      }
    });
  }

  get privacyStatementDocumentUrl(): string {
    return this.privacyStatementService.documentUrl;
  }

  get drawerDirection() {
    return this.windowWidth < 900 ? 'rtl' : 'ltr';
  }

  get drawerOpen() {
    const open = this.windowWidth >= 900 && this.windowWidth < 1200;
    if (this.windowWidth >= 1200) {
      this.menuClass = 'menu menu-hidden';
    }
    if (open) {
      this.menuClass = 'menu';
    }
    return open;
  }

  get drawerType() {
    return this.windowWidth < 900 ? 'modal' : 'dismissible';
  }

  public isInRegistrationProcess(): boolean {
    return this.menuService.isInRegistrationProcess();
  }

  closeDrawer(): void {
    // close the drawer only on small screens
    if (this.layoutService.isSmallScreen()) {
      this.mdcDrawer.open = false;
    }
  }
}
