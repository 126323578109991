import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'au-label-value-list',
  templateUrl: './label-value-list.component.html',
  styleUrls: ['./label-value-list.component.scss']
})
export class LabelValueListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
