// source: https://stackoverflow.com/questions/55662448/ngx-translate-default-text-if-key-is-missing-or-translation-file-is-being-loaded
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.interpolateParams["default"] || '';
    }
    return params.key;
  }
}
