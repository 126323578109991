import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: 'au-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit {
  @Input() label: string | undefined = undefined;
  @Input() helper = ' ';
  @Input() controlName: string;
  @Input() items: { value: unknown, label: string }[] = [];

  public form: FormGroup;
  public control: AbstractControl;

  constructor( public controlContainer: ControlContainer) {}
    
  ngOnInit() {
    this.form = (this.controlContainer.control as FormGroup);
    this.control = this.form.controls[this.controlName];
  }
}
