import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Pipe({
  name: 'localeDate'
})

/** Applies dynamic locale value from storage.service. */
export class LocaleDatePipe extends DatePipe implements PipeTransform {

  constructor(private storageService: StorageService) {
    super(storageService.language);
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    locale = locale ?? this.storageService.language;
    timezone = timezone ?? 'UTC';
    const result = super.transform(value, format, timezone, locale);
    return result;
  }
}
