<au-page-overlay  routerLink="/" title="">
  <au-page-banner image="/assets/images/information-banner.jpg"></au-page-banner>
  <au-page-content noTopbar>
    <au-page-content-section fixedFooter>
      <h1 mdcHeadline5>{{titleTranslationKey | translate}}</h1>
      <div mdcBody1 [innerHTML]="messageTranslationKey | translate"></div>
    </au-page-content-section>

    <au-page-content-footer fixedFooter>
      <button routerLink="/" class="button--main-action" mdc-button>
        <mdc-icon>close</mdc-icon>{{ 'CLOSE' | translate }}
      </button>
    </au-page-content-footer>

  </au-page-content>
</au-page-overlay>
