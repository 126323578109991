<section class='footer'>
    <section class='footer-cards max-width'>
        <article class='footer-card'>
            <h3>{{'FOOTER.SERVICES.TITLE' | translate}}</h3>
            <au-list type="links">
                <li><a href="https://www.arbounie.nl/werkgever/verzuim" target="_blank">{{'FOOTER.SERVICES.RETURN_TO_WORK' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/veiligheid" target="_blank">{{'FOOTER.SERVICES.SAFETY' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/gezondheid" target="_blank">{{'FOOTER.SERVICES.HEALTH' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/productiviteit" target="_blank">{{'FOOTER.SERVICES.PRODUCTIVITY' | translate}}</a></li>
            </au-list>
        </article>
        <article class='footer-card'>
            <h3>{{'FOOTER.ARBO_UNIE.TITLE' | translate}}</h3>
            <au-list type="links">
                <li><a href="https://www.arbounie.nl/werkgever/over-arbo-unie" target="_blank">{{'FOOTER.ARBO_UNIE.ABOUT' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werken-bij" target="_blank">{{'FOOTER.ARBO_UNIE.WORKING_AT' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/nieuws" target="_blank">{{'FOOTER.ARBO_UNIE.NEWS' | translate}}</a></li>
            </au-list>
        </article>
        <article class='footer-card'>
            <h3>{{'FOOTER.CONTACT.TITLE' | translate}}</h3>
            <au-list type="links">
                <li><a href="https://www.arbounie.nl/werkgever/service/contactformulier" target="_blank">{{'FOOTER.CONTACT.ONLINE_CONTACT' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/service/bel-mij-terug-formulier" target="_blank">{{'FOOTER.CONTACT.EXPERT_CALL_BACK' | translate}}</a></li>
                <li><a href="https://www.arbounie.nl/werkgever/service/bekijk-onze-locaties" target="_blank">{{'FOOTER.CONTACT.LOCATIONS' | translate}}</a></li>
            </au-list>
        </article>
        <article class='footer-card'>
            <h4>{{'FOOTER.NEED_ADVICE.TITLE' | translate}}</h4>
            <h4 class='color-primary-a'>
                <span class='icon-phone icon-20'></span>
                <a href="tel:{{'FOOTER.PHONE_NUMBER' | translate}}">{{'FOOTER.PHONE_NUMBER' | translate}}</a>
            </h4>
            <p>{{'FOOTER.NEED_ADVICE.WORKING_HOURS' | translate}}</p>
            <a class="locations-url" href="https://www.arbounie.nl/werkgever/service/bekijk-onze-locaties" target="_blank"><au-button>{{'FOOTER.NEED_ADVICE.LOCATIONS' | translate}}</au-button></a>
            <div class="social-media">
                <a href="https://twitter.com/arbounie" target="_blank" class='icon-twitter icon-48 color-primary-a'></a>
                <a href="https://www.linkedin.com/company/arbo-unie" target="_blank" class='icon-in icon-48 color-primary-a'></a>
                <a href="https://nl-nl.facebook.com/ArboUnie" target="_blank" class='icon-facebook icon-48 color-primary-a'></a>
            </div>
        </article>
    </section>
    <section class='footer-bottom'>
        <div class='footer-bottom-content max-width'>
            <img src="assets/images/logo_white.svg" class="logo" />
            <div class="links">
                <a href="https://www.arbounie.nl/werkgever/proclaimer" target="_blank">{{'FOOTER.PROCLAIMER' | translate}}</a>
                <a [href]="privacyStatementDocumentUrl" target="_blank">{{'FOOTER.PRIVACY' | translate}}</a>
                <a href="https://www.arbounie.nl/werkgever/algemene-voorwaarden" target="_blank">{{'FOOTER.REQUIREMENTS' | translate}}</a>
                <a href="https://www.arbounie.nl/werkgever/service/compliment-of-klacht" target="_blank">{{'FOOTER.COMPLAINTS' | translate}}</a>
                <a href="https://www.arbounie.nl/werkgever/cookies" target="_blank">{{'FOOTER.COOKIES' | translate}}</a>
                <a href="{{storagePath}}{{termsOfUseUrl}}{{language === 'en' ? termsOfUseEnPdfName : termsOfUseNlPdfName}}"
                   target="_blank">
                    {{'FOOTER.TERMS_OF_USE' | translate}}
                </a>
            </div>
        </div>
    </section>
</section>
