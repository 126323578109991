import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CanActivate, CanActivateChild, CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AuthorizationService } from '../../authorization/authorization.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authenticationService: AuthenticationService,
        private navigationService: NavigationService,
        private authorizationService: AuthorizationService) {
    }

    public canActivate() : Observable<boolean> {
      if (!this.isAuthenticated()) {
        return of(false);
      }

      return this.authorizationService.getOrRefreshUserInfo();
    }

    public canActivateChild(): boolean {
        return this.isAuthenticated();
    }

    public canLoad(route: Route): boolean {
        return this.isAuthenticated();
    }

    private isAuthenticated(): boolean {
      if (this.authenticationService.isAuthenticated()) {
          return true;
      }

      this.navigationService.goToLanding();

      return false;
    }
}
