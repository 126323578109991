import { Component,  EventEmitter,  Input, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationService } from '../../../../shared/services/navigation.service';

@Component({
  selector: 'au-page-content-topbar',
  templateUrl: './page-content-topbar.component.html',
  styleUrls: ['./page-content-topbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageContentTopbarComponent implements OnInit {
  @Input() title: string;
  @Input() showBackButton?: string;
  @Input() customBackAction?: string;
  @Output() backAction : EventEmitter<MouseEvent> = new EventEmitter(); 

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  public handleBackClick()
  {
    this.customBackAction ?
      this.backAction.emit() :
      this.navigationService.goBack();
  }
}
