import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'au-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
