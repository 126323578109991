import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JL } from 'jsnlog';

import { NavigationService } from '../services/navigation.service';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
    private errorHandlers: any = {
        302: _ => this.navigation.goToMaintenance(),
        400: _ => this.navigation.goToError(),
        401: _ => this.navigation.goToUnauthorized(),
        403: _ => this.navigation.goToUnauthorized(),
        500: _ => this.navigation.goToError()
    }; 

    constructor(private navigation: NavigationService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
              console.error(error);
              const authHeaderValue = error.headers.get('www-authenticate')||'no-value';
              JL('ServerError').error(`${error.message}, authvalue: ${authHeaderValue}`); 

              if (this.errorHandlers[error.status]) {
                this.errorHandlers[error.status]();
              }

              return throwError(error);
            }));
    }
}
