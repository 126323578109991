import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AuthorizationModule } from './authorization/authorization.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import {
  MsalModule,
  MSAL_CONFIG,
  MsalService,
  MSAL_CONFIG_ANGULAR,
  MsalAngularConfiguration,
} from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingModule } from './landing/landing.module';
import { LayoutModule } from './layout/layout.module';
import { MultiLanguageModule } from './shared/multi-language.module';
import { SharedModule } from './shared/shared.module';

import { UncaughtExceptionService } from './shared/services/uncaught-exception.service';
import { PlanningAndTaskClientService, VersionClientService } from './shared/services/http-services.service';
import { RegistrationStateService } from './registration/registration-state.service';
import { OverviewService } from './overview/overview.service';

import { API_BASE_URL } from './shared/services/http-services.service';
import { environment } from '../environments/environment';
import { VersionComponent } from './version/version.component';

import { TestComponent } from './test/test.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Configuration } from 'msal';

function MSALConfigFactory(): Configuration {

  return {
    auth: {
      clientId: environment.azureSettings.applicationId,
      authority: environment.azureSettings.authority + environment.azureSettings.signInPolicyId,
      validateAuthority: false,
      redirectUri: environment.azureSettings.signInRedirectUri,
      postLogoutRedirectUri: environment.azureSettings.logoutRedirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };
}

// This is required to specify because we are using msal-angular
// However this is not used since we do not use MsalGuard (which is used for logging in automatically in a route)
function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {};
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    VersionComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    SharedModule,
    LandingModule,
    AuthorizationModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    MultiLanguageModule.forRoot(),
    FormsModule,
    MsalModule
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl
    },
    {
      provide: ErrorHandler,
      useClass: UncaughtExceptionService
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    Title,
    VersionClientService,
    RegistrationStateService, // provide in app module to have this as a singleton for lazy loaded modules too
    OverviewService,          // provide in app module because OverviewService is used in overview and authorizations and hold caches so must be a singleton
    PlanningAndTaskClientService // Injected in OverviewService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
