<div id="{{category.fragment}}" class="accordion-category">
  <button routerLink="./" fragment="{{category.fragment}}" mdc-button class="accordion-category-header" (click)="itemClick()">
    <div class="accordion-category-title">{{category.categoryTitle}}</div>
    <mdc-icon *ngIf="category.expanded" class="accordion-category-header-icon">keyboard_arrow_up</mdc-icon>
    <mdc-icon *ngIf="!category.expanded" class="accordion-category-header-icon">keyboard_arrow_down</mdc-icon>
  </button>
  <div *ngIf="category.expanded" class="accordion-category-content">
    <au-accordion-item *ngFor="let subject of category.subjects" [subject]="subject"></au-accordion-item>
  </div>
</div>
