import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from '../shared/guards/anonymous.guard';
import { SignupSigninRedirectGuard } from './signup-signin-redirect/signup-signin-redirect.guard';

import { LandingComponent } from './landing.component';
import { SignupSigninRedirectComponent } from './signup-signin-redirect/signup-signin-redirect.component';

const routes: Routes = [
    {
        path: 'landing',
        component: LandingComponent,
        canActivate: [AnonymousGuard],
        data: {
          routeTitle: 'LANDING.ROUTE_TITLE'
        }
    },
    {
        path: 'signin-oidc',
        component: SignupSigninRedirectComponent,
        canActivate: [SignupSigninRedirectGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LandingRoutingModule {
}
