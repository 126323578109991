import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { MaintenanceClientService } from '../shared/services/http-services.service';
import { appConstant } from '../shared/constants/app.constant';

@Component({
    selector: 'au-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent  implements OnInit, OnDestroy {
  public isBackendOnline$: Observable<string>;

  constructor(
    private authenticationService: AuthenticationService,
    private maintenanceClientService: MaintenanceClientService
    ) {
  }

  ngOnInit(): void {
    document.body.setAttribute(appConstant.noMobileMenuAttribute, '');
    this.isBackendOnline$ = this.maintenanceClientService.isBackendOnline();
  }

  ngOnDestroy(): void {
    document.body.removeAttribute(appConstant.noMobileMenuAttribute);
  }

  public logIn(): void {
      this.authenticationService.logIn();
  }
}
