<mdc-form-field [formGroup]="form">
  <mdc-text-field [formControlName]="controlName"
                  [type]="type"
                  [maxlength]="maxLength"
                  [readonly]="readonly"
                  [label]="label"
                  [errorStateMatcher]="errorMatcher"
                  [validationMessage]="validationMessage"
                  [size]="size"
                  [helper]="helper"
                  (input)="onInput()"
                  autocomplete="off"
                  [outlined]="outlined"
                  #mdcTextField>
    <mdc-icon *ngIf="icon" mdcTextFieldIcon leading>{{ icon }}</mdc-icon>
  </mdc-text-field>
</mdc-form-field>
