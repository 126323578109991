
<ng-container *ngIf="employments.length > 1 && canReadClientCompanies">
  <mdc-menu #menu>
    <button mdc-button class="menu-header" (click)="changeMenuOpenState()">
      <div class="menu-header-label" mdcBody1>{{ 'EMPLOYMENT_FILTER.LABEL' | translate }}</div>
      <mdc-icon class="menu-header-icon">keyboard_arrow_down</mdc-icon>
    </button>
    <mdc-list>
      <mdc-list-item *ngFor="let employment of employments" [selected]="employment.key?.equals(selectedEmploymentKey)" (selectionChange)="setSelectedEmploymentKey(employment.key)">{{ employment.name }}</mdc-list-item>
    </mdc-list>
  </mdc-menu>

  <button mdc-fab extended position='bottomLeft' (click)="changeMenuOpenState()" #fab>
    <div class="label-container">
      <mdc-fab-label>{{ 'EMPLOYMENT_FILTER.LABEL' | translate }}</mdc-fab-label>
      <mdc-fab-label class="employer">{{ selectedEmploymentName }}</mdc-fab-label>
    </div>
    <mdc-icon>keyboard_arrow_up</mdc-icon>
  </button>
</ng-container>
