import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { AuthorizationItemModel, PendingQuestionnaireItemModel, PlanningAndTaskClientService, PermissionType } from '../shared/services/http-services.service';
import { environment } from '../../environments/environment';
import { routePathName } from '../shared/constants/route-pathname.constant';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';


@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  private pendingAuthorizationItems: Observable<AuthorizationItemModel[]>;
  private authorizationItemsForEmploymentId?: number;
  private pendingQuestionnaireItems: Observable<PendingQuestionnaireItemModel[]>;
  private questionnaireItemsForEmploymentId?: number;
  private _pendingAuthorizationItemsCount = 0;
  private _pendingQuestionnaireItemsCount = 0;
  private permissions: NgxPermissionsObject;
  private timer: any;

  private taskCountSubject:  BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor(
    private planningAndTaskClientService: PlanningAndTaskClientService,
    private permissionsService: NgxPermissionsService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  getTabItemCount(id: string): Observable<number> {
    if (id === routePathName.overviewPlanningAndTasks) {
      return this.taskCountSubject.asObservable();
    }
    return null;
  }

  public getPendingAuthorizationItems(employmentId?: number): Observable<AuthorizationItemModel[]> {    
    if (!this.pendingAuthorizationItems || this.authorizationItemsForEmploymentId !== employmentId) {
      this.authorizationItemsForEmploymentId = employmentId;
      this.pendingAuthorizationItems = this.planningAndTaskClientService.getAuthorizationItems(employmentId).pipe(
          publishReplay(1),
          refCount()
        );
      this.setTimeoutCache();
    }
    return this.pendingAuthorizationItems;
  }

  public getPendingQuestionnaireItems(employmentId?: number): Observable<PendingQuestionnaireItemModel[]> {    
    if (!this.pendingQuestionnaireItems || this.questionnaireItemsForEmploymentId !== employmentId) {
      this.questionnaireItemsForEmploymentId = employmentId;
      this.pendingQuestionnaireItems = this.planningAndTaskClientService.getPendingQuestionnaireItems(employmentId).pipe(
          publishReplay(1),
          refCount()
        );
      this.setTimeoutCache();
    }
    return this.pendingQuestionnaireItems;
  }

  set pendingAuthorizationItemsCount(count: number) {
    this._pendingAuthorizationItemsCount = count;
    this.publishTaskCount();
  }

  set pendingQuestionnaireItemsCount(count: number) {
    this._pendingQuestionnaireItemsCount = count;
    this.publishTaskCount();
  }

  get taskCount(): number {
    return this._pendingAuthorizationItemsCount + this._pendingQuestionnaireItemsCount;
  }

  get hasPendingQuestionnairePermission(): boolean {
    return this.permissions[PermissionType[PermissionType.CanReadClientQuestionnaire]] !== undefined;
  }

  get hasAuthorizationPermission(): boolean {
    return this.permissions[PermissionType[PermissionType.CanReadClientEmpowerment]] !== undefined ||
      this.permissions[PermissionType[PermissionType.CanWriteClientEmpowerment]] !== undefined
  }

  private publishTaskCount() {
    this.taskCountSubject.next(this.taskCount !== 0 ? this.taskCount : undefined);
  }

  public clearCache() {
    this.pendingAuthorizationItems = null;
    this.authorizationItemsForEmploymentId = undefined;
    this.pendingQuestionnaireItems = null;
    this.questionnaireItemsForEmploymentId = undefined;
  }

  private setTimeoutCache() {
    if (this.timer) {
        clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.clearCache();
    }, parseInt(environment.requestCacheTimeMs, 10)); // clear cache after requestCacheTimeMs
  }
}
