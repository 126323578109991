import { Injectable } from '@angular/core';

import { EmployerClientService, IEmploymentModel } from '../../services/http-services.service';
import { Observable } from 'rxjs';
import { BaseEmploymentFilterService } from './base-employment-filter.service';
import { StorageService } from '../../services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class EmploymentFilterService extends BaseEmploymentFilterService {

  constructor(
    private employerClientService: EmployerClientService,
    private storageService: StorageService) {
    super();
  }

  protected getEmploymentsCall(): Observable<IEmploymentModel[]> {
    return this.employerClientService.getEmployments(this.storageService.employerId);
  }
}
