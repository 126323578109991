import { Directive, Input } from '@angular/core';

import { MenuItem } from '../models/menu-item.model';

@Directive({
    selector: '[auMenuItem]'
})
export class MenuItemDirective {
    @Input()
    private auMenuItem: MenuItem;

    @Input()
    public set isActive(value: boolean) {
        this.auMenuItem.isActive = value;
    }
}
