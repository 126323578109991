import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { RegistrationService } from '../../registration/registration.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { RegistrationStateService } from '../../registration/registration-state.service';
import { AppointmentService } from '../../appointment/services/appointment.service';
import { routePathName } from '../../shared/constants/route-pathname.constant';
import { AuthorizationService } from '../../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class SignupSigninRedirectGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private registrationStateService: RegistrationStateService,
    private registrationService: RegistrationService,
    private navigationService: NavigationService,
    private appointmentService: AppointmentService,
    private authorizationService: AuthorizationService,
    private router: Router) {
  }

  public canActivate(): boolean | Observable<UrlTree | boolean> {
    // If an accesstoken is requested then the AuthenticationState is undefined. This is done in a hidden iframe so output the guarded component output
    if (!this.authenticationService.hasAuthenticationState()) {
      return true;
    }

    if (!this.authenticationService.isAuthenticated()) {
      this.navigationService.goToLanding();
      return false;
    }

    return this.authorizationService.getOrRefreshUserInfo().pipe(
      mergeMap(() => this.goToPage())
    );
  }

  private goToPage(): Observable<UrlTree> {
    if (this.registrationStateService.canRegister()) {
      return this.registrationService.getRegistrationStep().pipe(
        // Return an UrlTree has to do with cancelling all other navigation events
        map(signUpStep => this.router.parseUrl(signUpStep))
      );
    }

    if (this.appointmentService.canReadAppointments()) {
      return this.appointmentService.hasUpcomingAppointments().pipe(
        map(val => val ?
          this.router.parseUrl(`${routePathName.employee}/${routePathName.appointments}/${routePathName.appointmentsUpcoming}`) :
          this.router.parseUrl(routePathName.employee))
      );
    }

    return of(this.router.parseUrl(routePathName.employee));
  }
}
