export const routePathName = {
  error: 'error',
  notAuthorized: 'niet-geautoriseerd',
  maintenance: 'onderhoud',
  landing: 'landing',
  employee: 'werknemer',

  overview: 'overzicht',
  overviewTimeline: 'tijdlijn',
  overviewPlanningAndTasks: 'plan-en-regel',
  overviewDocuments: 'documenten',

  appointments: 'afspraken',
  appointmentsUpcoming: 'aankomend',
  appointmentsDetail: 'detail',

  appointmentsScheduleEmploymentSelection: 'inplannen-werkgever-selectie',
  appointmentsScheduleAppointmentInformation: 'inplannen-afspraak-informatie',
  appointmentsScheduleClientInformation: 'inplannen-werknemer-informatie',

  appointmentsScheduleAppointmentSelection: 'inplannen-afspraak-selectie',
  appointmentsScheduleAppointmentVerification: 'inplannen-afspraak-controle',
  appointmentsScheduleAppointmentConfirmation: 'inplannen-afspraak-bevestiging',

  appointmentsRescheduleAppointmentSelection: 'verplaatsen-afspraak-selectie',
  appointmentsRescheduleAppointmentVerification: 'verplaatsen-afspraak-controle',
  appointmentsRescheduleAppointmentConfirmation: 'verplaatsen-afspraak-bevestiging',

  appointmentsCancel: 'annuleren-afspraak',
  appointmentsCancelConfirmation: 'annuleren-afspraak-bevestiging',

  authorizations: 'machtigingen',
  authorizationsHandle: 'afhandelen',
  authorizationsDetail: 'detail',
  generalInformation: 'kennisbank',
  profile: 'profiel',
  registration: 'registratie',
};



