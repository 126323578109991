import { Component, Input, OnInit, ViewChild, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective, NgForm, ControlContainer, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '../../angular-mdc/form-field/error-state-matcher';
import { MdcTextField } from '../../angular-mdc/textfield/text-field';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

/* Error when invalid control is touched */
export class AuErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control &&
              control.invalid &&
              control.touched // touched means that the error is not matched during the first time editing the value
      );
  }
}

@Component({
  selector: 'au-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit, OnDestroy {
  @ViewChild('mdcTextField', { static: false }) mdcTextField: MdcTextField;
  @Input() label: string | undefined = undefined;
  @Input() icon: string | undefined = undefined;
  @Input() type?= 'text';
  @Input() readonly?= false;
  @Input() maxLength?: number;
  @Input() size?: number;
  @Input() helper?: string;
  @Input() controlName: string;
  @Input() outlined = false;
  public form: FormGroup;
  public control: AbstractControl;
  public errorMatcher = new AuErrorStateMatcher();
  public validationMessage = ' ';
  private inputSubscription: Subscription;
  
  @HostListener('focusout')
  public onFocusOut(): void {
    this.updateError();
  }

  constructor(
    public controlContainer: ControlContainer, // to determine the formgroup: https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.form = (this.controlContainer.control as FormGroup);
    this.control = this.form.controls[this.controlName];

    // Subscribe to the status changes of the current control so that when an error is set using setError(), then we can update the errors
    this.inputSubscription = this.control.statusChanges.subscribe((status: string) => {
      if (status.toUpperCase() === 'INVALID')
        this.updateError();
    });
  }

  onInput(): void {
    if (this.control.touched)
      this.updateError();
  }

  private updateError(): void {
    this.validationMessage = ' '; // cannot be empty or null/undefined otherwise internal exceptions by setting the validationMessage
    if (this.form) {
      const helperTextElement = this.mdcTextField._foundationHelper._helperLineElement.firstElementChild;
      const helperTextValidationClass = 'mdc-text-field-helper-text--validation-msg';
      if (this.control && this.control.errors && this.control.touched) {
        const validatorNames: string[] = Object.keys(this.control.errors);

        // Default validation for automated messages
        const error = {
          key: `VALIDATION_MESSAGES.${validatorNames[0].toUpperCase()}`,
          value: this.control.errors[validatorNames[0]]
        };

        this.validationMessage = this.translateService.instant(error.key, error.value);

        // If no automated error message was set, then take the validationMessage and use it for getting a translation
        if (!this.validationMessage)
          this.validationMessage = this.translateService.instant(validatorNames[0]);

        this.mdcTextField.valid = false; // this is important otherwise the automatic validation does not show the message at the first time
        this.mdcTextField._foundationHelper.setContent(this.validationMessage);

        // This adds a separate class to the helper-text so that the first error is also displayed when an error is set using setError()
        if (helperTextElement && !helperTextElement.classList.contains(helperTextValidationClass))
          helperTextElement.classList.add(helperTextValidationClass);
      }
      else {
        this.mdcTextField.valid = true;
        this.mdcTextField._foundationHelper.setContent(this.helper);
        if (helperTextElement && helperTextElement.classList.contains(helperTextValidationClass))
          helperTextElement.classList.remove(helperTextValidationClass);
      }
    }
  }

  ngOnDestroy() {
    this.inputSubscription.unsubscribe();
  }
}
