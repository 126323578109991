<au-page>
  <au-page-banner image="/assets/images/information-banner-light.jpg" *ngIf="!layoutService.isSmallScreen()"></au-page-banner>
  <au-page-content noFooter>
    <au-page-content-topbar title="{{ 'GENERAL_INFORMATION.PAGE_TITLE' | translate }}"></au-page-content-topbar>
    <au-page-content-section>
      <au-accordion *ngIf="categories.length" [categories]="categories">
      </au-accordion>
    </au-page-content-section>
  </au-page-content>
</au-page>
