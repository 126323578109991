import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'au-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent implements OnInit {
  @Input() public label = '';

  constructor() { }

  ngOnInit(): void {
  }
}
