import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Category } from '../../../../general-information/models/category';

@Component({
  selector: 'au-accordion-category',
  templateUrl: './accordion-category.component.html',
  styleUrls: ['./accordion-category.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionCategoryComponent {
  @Input() public category: Category;

  constructor() {
  }

  public itemClick() {
    this.category.expanded = !this.category.expanded;

    if (!this.category.expanded)
      this.category.subjects.forEach((subject) => subject.expanded = false);
  }
}
