export const registerRoutePathName = {
  privacyStatement: 'privacy-verklaring',
  employee: 'werknemer',
  employer: 'werkgever',
  error: 'fout',
  errorActionBlocked: 'fout-actie-geblokkeerd',
  confirmation: 'bevestiging',
  existingRelation: 'bestaande-relatie',
  information: 'informatie'
};
