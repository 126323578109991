import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AuthorizationService } from './authorization/authorization.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from './authentication/authentication.service';
import { appConstant } from './shared/constants/app.constant';
import { routePathName } from './shared/constants/route-pathname.constant';

const SEPARATOR = ' - ';

@Component({
  selector: 'au-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private appTitle = 'Clientportal';
  private setTitleSubscription: Subscription;
  private initChatBotSubscription: Subscription;
  public isIframe = false;
  public isBooting = true;

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private authService: MsalService) {
    this.authorizationService.initialize();
    this.appTitle = this.translateService.instant('APP_TITLE');
  }

  ngOnInit() {
    document.body.setAttribute(appConstant.noMobileMenuAttribute, '');
    this.initializeRouterTitleEvent();
    this.initializeAuthentication();
    this.initializeChatBot();
  }

  ngOnDestroy() {
    this.setTitleSubscription?.unsubscribe();
    this.initChatBotSubscription?.unsubscribe();
  }

  // inspired: https://gist.github.com/katowulf/2145adce2423d1b2f984555a6c1e87fb
  private initializeRouterTitleEvent() {
    this.setTitleSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route) => route.snapshot),
      map((snapshot) => {
        this.isBooting = false;
        document.body.removeAttribute(appConstant.noMobileMenuAttribute);
        // Remove booting element using a ie11 proof method
        const bootingElement = document.getElementById('booting');
        bootingElement?.parentNode?.removeChild(bootingElement);
        if (snapshot.data.routeTitle) {
          return this.translateService.instant(snapshot.data.routeTitle);
        } else {
          return this.router.url.split('/').reduce((acc, frag) => {
            if (acc && frag) { acc += SEPARATOR; }
            return acc + frag;
          });
        }
      })).subscribe((pathString: string) => {
        this.titleService.setTitle(`${this.appTitle}${SEPARATOR}${pathString}`);
      });
  }

  private initializeAuthentication() {
    this.isIframe = window !== window.parent && !window.opener;

    this.authService.handleRedirectCallback((authError, response) => {
      this.authenticationService.tokenReceivedCallback(authError, response);
    });
  }

  private initializeChatBot() {
    this.initChatBotSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.selectChatBot(event.urlAfterRedirects);
    });
  }

  private selectChatBot(url: string) {
    // There are 3 chatbot instances
    const registrationChatBotId = 'dcf9456f-f2be-4cdb-88d3-712525417177'; // Q&A during registration: also available on Azure AD signup page
    const loginChatBotId = '9ee8198f-3a8c-47f4-8d6e-8adeb9bd33aa';        // Q&A during login: also available on Azure AD signin page
    const applicationChatBotId = '63984b2b-c755-4457-a045-77f499c00753';  // Q&A for application

    let chatId = applicationChatBotId;
    let showChatBotInput = true;

    // The route detemines whice chatbot is available
    if (url.startsWith(`/${routePathName.landing}`)) {
      chatId = loginChatBotId;
      showChatBotInput = false;
    } else if (url.startsWith(`/${routePathName.registration}`)) {
      chatId = registrationChatBotId;
      showChatBotInput = false;
    }

    const typelessWindow = window as any;
    // Check if chatbot script is available and initialized: is placed on index.html
    if (typelessWindow.wcc && typelessWindow.wcc.init) {
      // Check if specific chatbot is initialized
      if (!typelessWindow.wcc[chatId]) {
        typelessWindow.wcc.init(chatId);
        typelessWindow.wcc[chatId].push(['on', 'ui.init', () => {
          typelessWindow.wcc[chatId].push(['changeState', { showInput: showChatBotInput, }]);
        }]);
      }
    }
  }
}
