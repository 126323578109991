<div class="page-overlay">
  <mdc-top-app-bar [scrollTarget]="content">
    <mdc-top-app-bar-row>
      <mdc-top-app-bar-section [title]="title">
        <button mdcTopAppBarNavIcon (click)="close()">
          <mdc-icon>close</mdc-icon>
        </button>
      </mdc-top-app-bar-section>
    </mdc-top-app-bar-row>
  </mdc-top-app-bar>
  <div mdcTopAppBarFixedAdjust #content>
    <ng-content></ng-content>
  </div>
</div>
