import { Component, AfterContentInit, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../../layout/layout.service';
import { ViewportScroller } from '@angular/common';
import { Category } from '../../../general-information/models/category';

@Component({
  selector: 'au-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterContentInit, AfterViewInit {
  @Input()
  public categories: Category[] = [];

  constructor(private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private layoutService: LayoutService) {
  }

  ngAfterContentInit() {
    const fragment = this.route.snapshot.fragment;

    this.categories.forEach((category) =>
    {
      if (category.fragment === fragment) {
        category.expanded = true;
      }
      else {
        const foundItem = category.subjects.find((subject) => subject.fragment === fragment);
        if (foundItem) {
          category.expanded = true;
          foundItem.expanded = true;
        }
      }
    });
  }

  // Scrolls to the correct accordion-category / accordion-category-item on page load
  ngAfterViewInit() {
    this.initializeRouterScrollEvent(this.route.snapshot.fragment);
  }

  private initializeRouterScrollEvent(fragment: string) {
    // anchor navigation
    this.viewportScroller.setOffset(this.calculateScrollOffset);
    this.viewportScroller.scrollToAnchor(fragment);
  }

  private calculateScrollOffset = (): [number, number] => {
    const offsetY = this.layoutService.getTopbarHeight();
    return [0, offsetY];
  };
}
