import { AuAuthorizationGuard } from '../../../authorization/au-authorization.guard';
import { IGuardable } from '../../../authorization/iguardable.interface';

export class MenuItem implements IGuardable<MenuItem> {
    private translationKeyPrefix: string = 'MENU';

    private prefix: string;
    private _path: string;
    private _guard: AuAuthorizationGuard;

    public id: string;
    public labelTranslationKey: string;
    public children: MenuItem[];
    public isActive: boolean = false;

    public constructor(
        id: string,
        labelTranslationKey: string,
        path: string,
        children: MenuItem[] = []) {

        this.id = id;
        this.labelTranslationKey = `${this.translationKeyPrefix}.${labelTranslationKey}`;
        this.path = path;
        this.children = children;

        this._guard = new AuAuthorizationGuard();

        this.prefix = '';
    }

    public guardedBy(guard: AuAuthorizationGuard): MenuItem {
        this._guard = guard;

        return this;
    }

    public get guard(): AuAuthorizationGuard {
        return this._guard;
    }

    public set path(value: string) {
        this._path = value;
    }

    public get path(): string {
        if (this.prefix) {
            return `${this.prefix}/${this._path}`;
        }

        return this._path;
    }

    public hasChildren(): boolean {
        return this.children && this.children.length !== 0;
    }

    public buildChildrenPaths(): void {
        this.children.forEach((i: MenuItem) => i.attachPrefix(this.path));
    }

    private attachPrefix(prefix: string): void {
        this.prefix = prefix;
    }
}
