import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'au-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {
  @Input() image: string;
  @Input() imageSet: string;
  @Input() imageSizes = '100vw';

  constructor() { }

  ngOnInit(): void {
  }

}
