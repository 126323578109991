import {NgModule} from '@angular/core';

import {MdcTopAppBarModule} from './angular-mdc/top-app-bar/module';
import {MdcIconModule} from './angular-mdc/icon/module';
import {MdcDrawerModule} from './angular-mdc/drawer/module';
import {MdcListModule} from './angular-mdc/list/module';
import {MdcButtonModule} from './angular-mdc/button/module';
import {MdcTabBarModule} from './angular-mdc/tab-bar/module';
import {MdcCheckboxModule} from './angular-mdc/checkbox/module';
import {MdcFabModule} from './angular-mdc/fab/module';
import {MdcElevationModule} from './angular-mdc/elevation/module';
import {MdcLinearProgressModule} from './angular-mdc/linear-progress/module';
import {MdcMenuModule} from './angular-mdc/menu/module';
import {MdcRadioModule} from './angular-mdc/radio/module';
import {MdcRippleModule} from './angular-mdc/ripple/module';
import {MdcSelectModule} from './angular-mdc/select/module';
import {MdcTextFieldModule} from './angular-mdc/textfield/module';
import {MdcTypographyModule} from './angular-mdc/typography/module';

const MDC_MODULES = [
  MdcButtonModule,
  MdcCheckboxModule,
  MdcDrawerModule,
  MdcElevationModule,
  MdcFabModule,
  MdcIconModule,
  MdcLinearProgressModule,
  MdcListModule,
  MdcMenuModule,
  MdcRadioModule,
  MdcRippleModule,
  MdcSelectModule,
  MdcTabBarModule,
  MdcTextFieldModule,
  MdcTopAppBarModule,
  MdcTypographyModule
];

@NgModule({
  exports: [MDC_MODULES]
})
export class MaterialModule {}
