import { Component, Input } from '@angular/core';

@Component({
  selector: 'au-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input()
  public type: 'links' = 'links';
}
