import { OnInit, Directive } from '@angular/core';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { MenuItem } from '../models/menu-item.model';
import { MenuService } from '../services/menu.service';
import { StorageService } from '../../../shared/services/storage.service';
import { LanguageService } from '../../../shared/services/language.service';

@Directive()
export abstract class BaseMenuComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  public languageIsDutch = false;

  constructor(
    private authenticationService: AuthenticationService,
    protected storageService: StorageService,
    protected menuService: MenuService,
    private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.languageIsDutch = this.languageService.languageIsDutch;
    this.loadMenuItems();

    this.authenticationService.onClearAuthentication().subscribe(() => {
      // reload menuitems if authentication is cleared
      this.loadMenuItems();
    });
  }

  public logIn(): void {
    this.authenticationService.logIn();
  }

  public logOut(): void {
    this.authenticationService.logOut();
  }

  public isLoggedIn(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public setLanguageToDutch(): void {
    this.languageService.setLanguageToDutch();
  }

  public setLanguageToEnglish(): void {
    this.languageService.setLanguageToEnglish();
  }

  public get userName(): string {
    return this.storageService.userFirstName;
  }

  private loadMenuItems(): void {
    this.menuService.getItems()
      .subscribe((items: MenuItem[]) => {
        this.menuItems = items;
      });
  }
}
