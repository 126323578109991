import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MenuService } from '../menu/services/menu.service';

@Component({
  selector: 'au-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(
    public layoutService: LayoutService,
    private menuService: MenuService ) { }

  ngOnInit(): void {
  }

  public redirectFromLogo(): void {
    this.menuService.redirectFromLogo();
  }
}
