import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { StorageService } from '../shared/services/storage.service';
import { PermissionType, CurrentUserClientService } from '../shared/services/http-services.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { RegistrationStateService } from '../registration/registration-state.service';
import { map, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(
    private permissionsService: NgxPermissionsService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private registrationStateService: RegistrationStateService,
    private currentUserClientService: CurrentUserClientService) {

    this.authenticationService.onAccessTokenExpired.subscribe(_ => this.clearPermissions());
  }

  public initialize(): void {
    if (this.storageService.permissions !== null) {
      this.permissionsService.flushPermissions();
      this.permissionsService.addPermission(this.storageService.permissions);
    }
  }

  public getOrRefreshUserInfo(): Observable<boolean> {
    // Permissions will be cleared if the session is expired
    // In case of refreshing the access token, we also have to refresh the permissions
    if (this.storageService.userName != null && this.storageService.userName.length > 0 && this.storageService.permissions != null) {
      return of(true);
    }

    return this.refreshUser();
  }

  public refreshUser(): Observable<boolean> {
    let addPermissions: PermissionType[] = [];
    // If user has registered with employerlink and choose the non existion relation then the user can schedule a working conditions consult so the
    //  PermissionType.CanReadClientTasks is added to show the plan & tasks menu option and to start an AO appointment
    if (this.registrationStateService && this.registrationStateService.isLoggedInAsNonExistingRelationWithEmployerLink) {
      addPermissions = [PermissionType.CanReadClientTasks,
                        PermissionType.CanReadClientAppointments,
                        PermissionType.CanReadClientAppointmentsAO,
                        PermissionType.CanWriteClientAppointments,
                        PermissionType.CanWriteClientAppointmentsAO];
    }

    this.storageService.clearUser();

    return this.currentUserClientService.getCurrentUser().pipe(
      tap(currentUser => {
        this.storageService.setUser(currentUser.userEmail, currentUser.firstName, currentUser.displayName, currentUser.clientRole);
        currentUser.permissions.push(...addPermissions);
        this.refreshPermissions(currentUser.permissions);
      }),
      map(_ => true)
    );
  }

  private refreshPermissions(permissions: PermissionType[]) {
    this.clearPermissions();

    if (permissions && permissions.length > 0) {
      const stringPermissions = permissions.map(rc => PermissionType[rc]);
      this.permissionsService.addPermission(stringPermissions);
      this.storageService.permissions = stringPermissions;
    }
  }

  private clearPermissions(): void {
    this.permissionsService.flushPermissions();
    this.storageService.permissions = null;
  }
}
