import { Component, Input } from '@angular/core';

export class ActionItem
{
  public constructor(public callback: () => void, public label: string) { }
}

@Component({
  selector: 'au-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent {
  @Input() items: { callback: () => void, label: string }[] = [];

  public selectionChange = (item: ActionItem) => {
    item.callback();
  };
}
