import { TranslateCompiler } from '@ngx-translate/core';

export class CustomTranslateCompiler extends TranslateCompiler {  

  constructor() {
    super();
  }

  public compile(value: string, lang: string): string | Function {
    return value;
  }

  public compileTranslations(translations: any, lang: string): any {
    if (typeof window.URLSearchParams === 'function') {
      const params = new URLSearchParams(window.location.search);
    
      if (params.has('slk')) {
        this.addLanguageKey(translations, '', params.get('slk'));
      }
    }

    return translations;
  }

  private addLanguageKey(object, path, showLanguageKeyMode)
  {
    const keyDelimeter = path.length === 0 ? '' : '.';
    
    for (const key in object)
    {          
      if (typeof object[key] === 'object' && object[key] !== null) {        
        this.addLanguageKey(object[key], `${path}${keyDelimeter}${key}`, showLanguageKeyMode);
      }
      else {
        if (showLanguageKeyMode === '1') {
          // Show text and key
          object[key] = `${object[key]} (${path}${keyDelimeter}${key})`;
        } else if (showLanguageKeyMode === '2') {
          // Show only key instead of text
          object[key] = `[${path}${keyDelimeter}${key}]`;
        }
      }
    }
  }
}
