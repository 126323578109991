<div class="page-content__footer">
  <mdc-top-app-bar-row>
    <mdc-top-app-bar-section align="start">
      <ng-content select="[alignStartFooter]"></ng-content>
    </mdc-top-app-bar-section>
    <mdc-top-app-bar-section align="end">
      <ng-content></ng-content>
    </mdc-top-app-bar-section>
  </mdc-top-app-bar-row>
</div>
