import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Language } from '../../services/http-services.service';

@Component({
  selector: 'au-choose-language',
  templateUrl: './choose-language.component.html',
  styleUrls: ['./choose-language.component.scss']
})
export class ChooseLanguageComponent implements OnInit, OnDestroy {
  public languageIsDutch = false;
  public languageForm = this.fb.group({
    language: ['']
  });
  public languageItems = [
    { value: Language.English, label: this.translateService.instant('CHOOSE_LANGUAGE.SET_LANGUAGE_ENGLISH') },
    { value: Language.Dutch, label: this.translateService.instant('CHOOSE_LANGUAGE.SET_LANGUAGE_DUTCH') }
  ];
  private languageSubscription: Subscription;

  constructor(private fb: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.languageIsDutch = this.languageService.languageIsDutch;
    this.languageForm.patchValue({ language: this.languageIsDutch ? Language.Dutch : Language.English });

    this.languageSubscription = this.languageForm.get('language').valueChanges.subscribe(value => {
      if (value === Language.Dutch)
        this.languageService.setLanguageToDutch();
      else
        this.languageService.setLanguageToEnglish();
    });
  }

  ngOnDestroy() {
    this.languageSubscription.unsubscribe();
  }
}
