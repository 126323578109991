import { NgxPermissionsObject } from 'ngx-permissions';

export class AuAuthorizationGuard {
  private _requiredPermissions: string[];

  constructor() {
    this._requiredPermissions = [];
  }

  public requiresPermissions(permissions: string[]): AuAuthorizationGuard {
    this._requiredPermissions = permissions;

    return this;
  }

  public hasPermissions(permissions: NgxPermissionsObject): boolean {
    return this._requiredPermissions.every((requiredPermission => permissions[requiredPermission] !== undefined));
  }

  public get requiredPermissions(): string[] {
    return this._requiredPermissions;
  }
}
