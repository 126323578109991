import { Component } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';

import { environment } from '../../../environments/environment';
import { storageUrlBuilder } from '../../../environments/storage-url-builder';
import { PrivacyStatementService } from '../../shared/services/privacy-statement.service';

@Component({
    selector: 'au-desktop-footer',
    templateUrl: './desktop-footer.component.html',
    styleUrls: ['./desktop-footer.component.scss']
})
export class DesktopFooterComponent {
    public storagePath: string = environment.azureSettings.storage;
    public termsOfUseUrl: string = storageUrlBuilder.public.documents.path;
    public termsOfUseEnPdfName: string = storageUrlBuilder.public.documents.termsOfUse.en;
    public termsOfUseNlPdfName: string = storageUrlBuilder.public.documents.termsOfUse.nl;
    public language: string = this.storageService.language;

  constructor(private storageService: StorageService,
    private privacyStatementService: PrivacyStatementService) {
    }

  get privacyStatementDocumentUrl(): string {
    return this.privacyStatementService.documentUrl;
  }
}
