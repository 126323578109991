import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { StorageService } from '../../../shared/services/storage.service';

import { BaseMenuComponent } from '../abstract-components/base-menu.component';
import { MenuService } from '../services/menu.service';
import { LanguageService } from '../../../shared/services/language.service';


@Component({
  selector: 'au-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss']
})
export class DesktopMenuComponent extends BaseMenuComponent {

  constructor(
    authenticationService: AuthenticationService,
    storageService: StorageService,
    menuService: MenuService,
    languageService : LanguageService,
    private router: Router) {

    super(authenticationService, storageService, menuService, languageService);
  }

  public redirectFromLogo(): void {
    this.menuService.redirectFromLogo();
  }

  public isInRegistrationProcess(): boolean {
    return this.menuService.isInRegistrationProcess();
  }
}
