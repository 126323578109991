import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { EmployerClientService } from './services/http-services.service';

import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';

import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { ButtonComponent } from './components/button/button.component';
import { EmploymentFilterComponent } from './components/employment-filter/employment-filter.component';
import { ChooseLanguageComponent } from './components/choose-language/choose-language.component';
import { ActionListComponent } from './components/action-list/action-list.component';
import { InformationBoxComponent } from './components/information-box/information-box.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileValueAccessorDirective } from './components/file-input/file-value-accessor.directive';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ChronologyContentComponent } from './components/chronology/chronology-content/chronology-content.component';
import { ChronologyDateComponent } from './components/chronology/chronology-date/chronology-date.component';
import { ChronologyComponent } from './components/chronology/chronology.component';
import { AccordionCategoryComponent } from './components/accordion/accordion-category/accordion-category.component';
import { AccordionItemComponent } from './components/accordion/accordion-category/accordion-item/accordion-item.component';
import { LabelValueListComponent } from './components/label-value-list/label-value-list.component';
import { LabelValueComponent } from './components/label-value-list/label-value/label-value.component';
import { ListComponent } from './components/list/list.component';

import { CustomerHttpInterceptor } from './httpinterceptors/customer.httpinterceptor';
import { ServerErrorsInterceptor } from './httpinterceptors/server-errors.httpinterceptor';
import { MultiLanguageModule } from './multi-language.module';
import { CallbackPipe } from './pipes/callback.pipe';
import { LocaleDatePipe } from './pipes/localedate.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';

const moduleComponents: any[] = [
  SelectComponent,
  ButtonComponent,
  InputComponent,
  ChronologyComponent,
  ChronologyContentComponent,
  ChronologyDateComponent,
  EmploymentFilterComponent,
  ChooseLanguageComponent,
  ActionListComponent,
  InformationBoxComponent,
  FileInputComponent,
  AvatarComponent,
  AccordionItemComponent,
  AccordionCategoryComponent,
  AccordionComponent,
  LabelValueListComponent,
  LabelValueComponent,
  ListComponent,
];

const directives = [
  FileValueAccessorDirective
];

registerLocaleData(localeNl);
registerLocaleData(localeEn);

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    HttpClientModule,
    MultiLanguageModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    FormsModule,
  ],
  exports: [
    moduleComponents,
    directives,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    MultiLanguageModule,
    LocaleDatePipe,
    CallbackPipe,
    WithLoadingPipe
  ],
  declarations: [
    moduleComponents,
    directives,
    LocaleDatePipe,
    CallbackPipe,
    WithLoadingPipe,
  ],
  providers: [
    EmployerClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomerHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-NL'
    }
  ]
})
export class SharedModule { }
