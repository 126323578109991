export const storageUrlBuilder: any = {
    public: {
        documents: {
            path: 'public/documents/',
            termsOfUse: {
                en: 'TermsOfUse_EN.pdf',
                nl: 'TermsOfUse_NL.pdf'
            },
            userManual: {
                en: 'UserManual_EN.pdf',
                nl: 'UserManual_NL.pdf'
            }
        },
        images: {
            workers: {
                path: 'public/images/employees/',
                employees: 'Serge_van_Amstel.jpg',
                contracts: 'Marc_van_de_Kerkhof.jpg',
                finance: 'Jan_van_den_Hoogen.jpg',
                reports: 'Annechien_Veenemans.jpg',
                organization: 'Rita_Paijmans.jpg',
                userAccounts: 'Anita_Koops.jpg'
            }
        }
    }
};