import { Component, OnInit } from '@angular/core';
import { ContentService, ContentItem } from '../shared/services/content.service';
import { StorageService } from '../shared/services/storage.service';
import { LayoutService } from '../layout/layout.service';
import { Language } from '../shared/services/http-services.service';
import { plainToClass } from 'class-transformer';
import { Category } from './models/category';

const contentFolder = 'general-information';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  public categories: Category[] = [];
  private language: Language;

  constructor(
    public layoutService: LayoutService,  // for use in template
    private contentService: ContentService,
    private storageService: StorageService) {
    this.language = this.storageService.language;
  }

  ngOnInit(): void {
    this.contentService.getContent(`${contentFolder}/${this.generalInformationConfig}`, this.language).subscribe(contentItem => {
      this.parseGeneralInformationConfig(contentItem);
    });
  }

  private parseGeneralInformationConfig(contentItem: ContentItem) {
    const array : unknown[] = JSON.parse(contentItem.content);
    this.categories = plainToClass(Category, array);

    this.categories.forEach((category) => {
      category.fragment = category.categoryTitle;

      category.subjects.forEach((subject) => {
        subject.fragment = `id-${subject.contentUrl}`; // use content url as id, because this is language independent
        subject.contentUrl = `${contentFolder}/${subject.contentUrl}`;
      });
    });
  }

  private get generalInformationConfig() {
    switch (this.language) {
      case Language.Dutch:
        return 'nl.json';
      case Language.English:
        return 'en.json';
    }
  }
}
