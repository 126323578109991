import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { Language } from './http-services.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public languageIsDutch = false;

  constructor(private storageService: StorageService)
  {
    this.languageIsDutch = this.isLanguageDutch();
  }

  public setLanguageToDutch(): void {
    if (this.languageIsDutch) {
      return;
    }

    this.storageService.language = Language.Dutch;
    location.reload();
  }

  public setLanguageToEnglish(): void {
    if (!this.languageIsDutch) {
      return;
    }

    this.storageService.language = Language.English;
    location.reload();
  }

  public get language(): Language {
    return this.storageService.language;
  }

  private isLanguageDutch(): boolean {
    return this.storageService.language === Language.Dutch;
  }
}
