<div class="desktop-menu">
  <div class="first-level">
    <div class="max-width">
      <div class="roles">
        <a href="https://www.arbounie.nl/werkgever" target="_blank">{{'MENU.ROLES.EMPLOYER' | translate}}</a>
        <a class="active" href="https://www.arbounie.nl/werknemer" target="_blank">{{'MENU.ROLES.EMPLOYEE' | translate}}</a>
        <a href="https://www.arbounie.nl/werken-bij" target="_blank">{{'MENU.ROLES.WORKING_FOR' | translate}}</a>
      </div>

      <div class="user-area">
        <div>
          <ng-container *ngIf="!isInRegistrationProcess()">
            <span *ngIf="isLoggedIn()" [innerHTML]="(userName ? 'HEADER.WELCOME_MESSAGE_WITH_NAME' : 'HEADER.WELCOME_MESSAGE_WITHOUT_NAME') | translate : { name: userName }">
            </span>

            <a *ngIf="!isLoggedIn()" (click)="logIn()"><span class="icon-person"></span>{{ 'LOGIN' | translate }}</a>
            <a *ngIf="isLoggedIn()" (click)="logOut()"> <span class="icon-person"></span>{{ 'LOGOUT' | translate }}</a>
          </ng-container>
          <span class="language">
            <span class="icon-globe"></span>
            <a (click)="setLanguageToDutch()" [ngClass]="{'active': languageIsDutch }"> {{ 'HEADER.DUTCH' | translate }}</a> |
            <a (click)="setLanguageToEnglish()" [ngClass]="{'active': !languageIsDutch }"> {{ 'HEADER.ENGLISH' | translate }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="second-level">
    <div class="max-width">
      <img (click)="redirectFromLogo()" src="assets/images/logo.svg" class="logo" />
      <div class="menu" *ngIf="!isInRegistrationProcess()">
        <a *ngFor="let item of menuItems"
           [class.has-children]="true"
           routerLinkActive="active"
           [routerLink]="item.path"
           [auMenuItem]="item"
           #rla="routerLinkActive"
           [isActive]="rla.isActive">{{item.labelTranslationKey | translate}}</a>
      </div>
    </div>
  </div>
</div>
