export const environment: any = {
  production: true,
  language: 'nl',
  apiUrl: 'https://#{{ClientPortalHost}}#',
  requestCacheTimeMs: '#{{RequestCacheTimeMs}}#',
  azureSettings: {
    applicationId: '#{{App_AzureAd_ApplicationId}}#',
    b2cScopes: ['https://#{{AzureAd_Domain}}#.onmicrosoft.com/arbounieclientportalapi/all.full'],
    signInRedirectUri: 'https://#{{ClientPortalHost}}#/signin-oidc',
    logoutRedirectUri: 'https://#{{ClientPortalHost}}#',

    /*
     * this must be constructed from three parts:
     * instance / domain / sign-in policy
     */
    authority: 'https://' + '#{{AzureAd_Domain}}#' + '.b2clogin.com' +
      '/' + '#{{AzureAd_Domain}}#.onmicrosoft.com' + '/',

    signUpSignInPolicyId: '#{{AzureAd_SignUpSignInPolicy}}#',
    signInPolicyId: '#{{AzureAd_SignInPolicy}}#',
    passwordResetPolicyId: '#{{AzureAd_PasswordResetPolicy}}#',
    storage: '#{{AzureStorageAccountUrl}}#'
  }
};
