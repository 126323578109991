import { NgModule } from '@angular/core';
import { AuthorizationRoutingModule } from '../authorization/authorization-routing.module';
import { AuthorizationService } from '../authorization/authorization.service';
import { CurrentUserClientService } from '../shared/services/http-services.service';
import { UnauthorizedComponent } from '../authorization/unauthorized/unauthorized.component';
import { LayoutModule } from '../layout/layout.module';
import { MultiLanguageModule } from '../shared/multi-language.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
      AuthorizationRoutingModule,
      LayoutModule,
      SharedModule,
      MultiLanguageModule
    ],
    providers: [
      AuthorizationService,
      CurrentUserClientService
    ],
    declarations: [
      UnauthorizedComponent
    ]
})
export class AuthorizationModule { }
