import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { RegistrationStateService } from '../../registration/registration-state.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService) {
  }

  public canActivate(): boolean {
    if (!this.authenticationService.isAuthenticated()) {
        return true;
    }

    this.navigationService.goToHome();

    return false;
  }
}
