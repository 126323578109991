import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Language } from './http-services.service';

export class ContentItem {
  id: string;
  language: Language;
  content: string;

  constructor(contentItem?: ContentItem) {
    this.id = contentItem.id;
    this.language = contentItem.language;
    this.content = contentItem.content;
  }
}

export enum ContentLocation {
  Portal = 0,
  AzureStorage = 1,
}

// Sort of headless cms api. For now get the content from static files on wwwroot
@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private contentLocation = '/';

  constructor(private httpClient: HttpClient) { }

  getContentUrl(id: string, language: Language): string {
    return `${this.contentLocation}content/${language}/${id}`;
  }

  getContent(id: string, language: Language): Observable<ContentItem> {
    return this.httpClient.get(this.getContentUrl(id, language), { responseType: 'text' })
      .pipe(
        map(response => new ContentItem({ id, language, content: response }))
      );
  }

  getDocumentUrl(id: string, language: Language): string {
    return `${this.contentLocation}documents/${language}/${id}`;
  }

  setContentLocation(contentLocation: ContentLocation) {
    switch (contentLocation) {
      case ContentLocation.Portal:
        this.contentLocation = '/';
        break;
      case ContentLocation.AzureStorage:
        this.contentLocation = `${environment.azureSettings.storage}public/`;
        break;
    }
  }
}
