import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MdcTabIndicator} from './tab-indicator';

@NgModule({
  imports: [CommonModule],
  exports: [MdcTabIndicator],
  declarations: [MdcTabIndicator]
})
export class MdcTabIndicatorModule { }
