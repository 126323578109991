import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subject, fromEvent } from 'rxjs';
import { map, startWith, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export type windowViewportSize = {
  width: number;
  height: number;
};

@Injectable({
  providedIn: 'root'
})
export class LayoutService implements OnDestroy {
  private toggleMenuSubject = new Subject<any>();
  private unsubscriber$: Subject<any> = new Subject();
  private resizeObservable: Observable<windowViewportSize>;

  constructor(public breakpointObserver: BreakpointObserver) {
    this.resizeObservable = fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
        startWith({ target: window }),
        map(resizeEvent => {
          return {
            width: (resizeEvent.target as Window).innerWidth,
            height: (resizeEvent.target as Window).innerHeight
          } as windowViewportSize
        }),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber$)
      );

    this.resizeObservable.subscribe(size => {
      this.setCustomCssPropertyVh(size.height);
    });
  }

  isSmallScreen(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 899px)');
  }

  isTabletLandscapeScreen(): boolean {
    return this.breakpointObserver.isMatched('(min-width: 900px) and (max-width: 1199px)');
  }

  hasTopbar(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 1199px)');
  }

  isSmallTopbar(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 599px)');
  }

  getTopbarHeight(): number {
    if (this.hasTopbar()) {
      // These heights are in pixels and are defined in the mdc-top-bar
      return this.isSmallTopbar() ? 56 : 64;
    }
    else {
      return 0;
    }
  }

  setToggleMenu() {
    this.toggleMenuSubject.next();
  }

  onToggleMenu(): Observable<any> {
    return this.toggleMenuSubject.asObservable();
  }

  onResize(): Observable<windowViewportSize> {
    return this.resizeObservable;
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  // Here for: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  private setCustomCssPropertyVh(windowInnerHeight: number) {
    // On mobile devices you cannot use viewport units (ie. vh) to determine the viewport height of screen,
    //  so use javascript window.innerHeight to get height and set in custom property so that it can be
    //  used in css
    document.documentElement.style.setProperty('--wih', `${windowInnerHeight}px`);
  }
}
