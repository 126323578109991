import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, publishReplay, refCount } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { RegistrationStateService, RegistrationType } from './registration-state.service';
import { RegistrationClientService, RegisterStatus, EmployerModel, ExistingRelationModel, ValidationSettingsModel, SettingsClientService } from '../shared/services/http-services.service';
import { routePathName } from '../shared/constants/route-pathname.constant';
import { registerRoutePathName} from './registration-routing.constants';

export enum ConfirmationType {
    Employee = 0,
    EmployeeOfEmployer,
    NoExistingRelation,
    IsAlreadyLinked,
    Error
};

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private validationSettings: Observable<ValidationSettingsModel>;

  constructor(
    private authenticationService: AuthenticationService,
    private registrationClientService: RegistrationClientService,
    private registrationStateService: RegistrationStateService,
    private settingsClientService: SettingsClientService) { }

  public getValidationSettings(): Observable<ValidationSettingsModel> {
    if (!this.validationSettings) {
      this.validationSettings = this.settingsClientService.getValidationSettings().pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this.validationSettings;
  }

  getRegistrationStep(): Observable<string> {
    if (this.registrationStateService.key) {
      if (this.registrationStateService.type === RegistrationType.Employee) {
        return of(`/${routePathName.registration}/${registerRoutePathName.confirmation}`);
      }

      return this.registrationClientService.isAlreadyLinkedToEmployee().pipe(
        map(isLinked => this.saveAlreadyLinkedEmployee(isLinked))
      );
    }

    return of(this.errorRoute);
  }

  isEmployeeKeyValid(employeeKey: string): Observable<boolean> {
    this.registrationStateService.type = RegistrationType.Employee;
    this.registrationStateService.key = employeeKey;
    return this.registrationClientService.isEmployeeKeyValid(employeeKey).pipe(
      tap(valid => this.registrationStateService.key = valid ? employeeKey : null )
    );
  }

  getEmployerByKey(employerKey: string): Observable<EmployerModel> {
    this.registrationStateService.type = RegistrationType.Employer;
    this.registrationStateService.key = employerKey;
    return this.registrationClientService.getEmployerByKey(employerKey);
  }

  get currentType(): RegistrationType {
    return this.registrationStateService.type;
  }

  get currentKey(): string {
    return this.registrationStateService.key;
  }

  get existingRelationInformation(): ExistingRelationModel {
    return this.registrationStateService.existingRelationInformation;
  }

  get errorRoute(): string {
    return `/${routePathName.registration}/${registerRoutePathName.error}`;
  }

  get errorActionBlockedRoute(): string {
    return `/${routePathName.registration}/${registerRoutePathName.errorActionBlocked}`;
  }

  get upcomingAppointmentsRoute(): string {
    return `${routePathName.employee}/${routePathName.appointments}/${routePathName.appointmentsUpcoming}`
  }

  get confirmationType(): ConfirmationType {
    if (this.registrationStateService.key) {
      if (this.registrationStateService.type === RegistrationType.Employee) {
        return ConfirmationType.Employee;
      }

      if (this.registrationStateService.type === RegistrationType.Employer) {
        if (this.registrationStateService.isAlreadyLinked === true) {
          return ConfirmationType.IsAlreadyLinked;
        }

        if (this.registrationStateService.existingRelation === false) {
          return ConfirmationType.NoExistingRelation;
        }

        if (this.registrationStateService.existingRelationInformation != null) {
          return ConfirmationType.EmployeeOfEmployer;
        }
      }
    }

    return ConfirmationType.Error;
  }

  saveExistingRelationQuestion(relationExists: boolean): string {
    this.registrationStateService.existingRelation = relationExists;

    return relationExists ?
            `/${routePathName.registration}/${registerRoutePathName.information}` :
            `/${routePathName.registration}/${registerRoutePathName.confirmation}`;
  }

  saveExistingRelationInformation(existingRelationInformation: ExistingRelationModel): string {
    this.registrationStateService.existingRelationInformation = existingRelationInformation;
    return `/${routePathName.registration}/${registerRoutePathName.confirmation}`;
  }

  saveAlreadyLinkedEmployee(isLinked: boolean): string {
    if (isLinked) {
      this.registrationStateService.isAlreadyLinked = true;
      return `/${routePathName.registration}/${registerRoutePathName.confirmation}`;
    }
    return `/${routePathName.registration}/${registerRoutePathName.existingRelation}`;
  }


  public signUp(): void {
    this.authenticationService.signUp(this.registrationStateService.type, this.registrationStateService.key);
  }

  public signIn(): void {
    this.authenticationService.signIn(this.registrationStateService.type, this.registrationStateService.key);
  }

  public clearAuthentication(): void {
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.clearAuthentication();
    }
  }

  registerEmployee(): Observable<RegisterStatus> {
    return this.registrationClientService.registerEmployee(this.currentKey);
  }

  registerEmployeeOfEmployer(): Observable<RegisterStatus> {
    return this.registrationClientService.registerEmployeeOfEmployer(this.currentKey, this.existingRelationInformation);
  }}
