import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';

import { RegistrationService } from '../registration/registration.service';
import { RegistrationClientService, AppointmentClientService, SettingsClientService } from '../shared/services/http-services.service';

import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { SignupSigninRedirectComponent } from './signup-signin-redirect/signup-signin-redirect.component';
import { AppointmentService } from '../appointment/services/appointment.service';
import { MaintenanceClientService } from '../shared/services/http-services.service';

@NgModule({
  imports: [
    LayoutModule,
    SharedModule,
    LandingRoutingModule
  ],
  declarations: [
    LandingComponent,
    SignupSigninRedirectComponent
  ],
  providers: [
    RegistrationService,
    RegistrationClientService,
    AppointmentService,
    AppointmentClientService,
    MaintenanceClientService,
    SettingsClientService
  ]
})
export class LandingModule {
}
