<au-desktop-menu></au-desktop-menu>
<mdc-drawer [ngClass]="menuClass"
            [open]="drawerOpen"
            [drawer]="drawerType"
            [dir]="drawerDirection"
            mdcTopAppBarFixedAdjust
            #mdcDrawer>
  <mdc-drawer-content>
    <mdc-list dir="ltr">
      <ng-container *ngIf="!isInRegistrationProcess()">
        <ng-container *ngFor="let item of menuItems">
          <a mdc-list-item (click)="closeDrawer()" [routerLink]="item.path" [activated]="router.isActive(item.path,false)" *ngIf="!item.hasChildren()">
            <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{item.labelTranslationKey | translate}}
          </a>
          <!-- If item has children then show the children and not the parent -->
          <ng-container *ngIf="item.hasChildren()">
            <a mdc-list-item (click)="closeDrawer()" [routerLink]="subItem.path" [activated]="router.isActive(subItem.path,false)" *ngFor="let subItem of item.children">
              <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{subItem.labelTranslationKey | translate}}
            </a>
          </ng-container>
        </ng-container>

        <a mdc-list-item (click)="logIn()" *ngIf="!isLoggedIn()">
          <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{ 'LOGIN' | translate }}
        </a>
        <a mdc-list-item (click)="logOut()" *ngIf="isLoggedIn()">
          <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{ 'LOGOUT' | translate }}
        </a>

        <mdc-list-divider></mdc-list-divider>

        <h6 mdcListGroupSubheader class="menu__group-subheader">{{ 'HEADER.SEE_ALSO' | translate }}</h6>
      </ng-container>
      <a mdc-list-item (click)="closeDrawer()" href="https://www.arbounie.nl/werkgever/proclaimer" target="_blank">
        <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{'FOOTER.PROCLAIMER' | translate}}
      </a>
      <a mdc-list-item (click)="closeDrawer()" [routerLink]="privacyStatementDocumentUrl" target="_blank">
        <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{'FOOTER.PRIVACY' | translate}}
      </a>
      <a mdc-list-item (click)="closeDrawer()" href="https://www.arbounie.nl/werkgever/cookies" target="_blank">
        <mdc-icon mdcListItemGraphic>keyboard_arrow_right</mdc-icon>{{'FOOTER.COOKIES' | translate}}
      </a>
    </mdc-list>
  </mdc-drawer-content>
</mdc-drawer>
<ng-content></ng-content>
