import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { routePathName } from '../../../shared/constants/route-pathname.constant';


import { MenuItem } from '../models/menu-item.model';

import { MenuFactory } from './menu.factory';

@Injectable()
export class MenuService {

  constructor(
    private authenticationService: AuthenticationService,
    private permissionsService: NgxPermissionsService,
    private router: Router ) {
  }

  public getItems(): Observable<MenuItem[]> {
    if (!this.authenticationService.isAuthenticated()) {
      return of([]);
    }

    return this.permissionsService.permissions$.pipe(
      map((permissions: NgxPermissionsObject) => {
        const menu: MenuItem[] = this.filterMenu(MenuFactory.menu, permissions);

        return this.computeItems(menu);
      })
    );
  }

  public redirectFromLogo(): void {
    if (!this.isInRegistrationProcess()) {
      this.router.navigate([`/${routePathName.employee}/${routePathName.overview}`]);
    }
  }

  public isInRegistrationProcess(): boolean {
    return this.router.url.startsWith(`/${routePathName.registration}`);
  }

  private filterMenu(menu: MenuItem[], permissions: NgxPermissionsObject): MenuItem[] {
    const filteredMenu: MenuItem[] = [];

    menu.forEach((item: MenuItem) => {
      filteredMenu.push(...this.filterItem(item, permissions));
    });

    return filteredMenu;
  }

  private filterItem(item: MenuItem, permissions: NgxPermissionsObject): MenuItem[] {

    if (item.guard.requiredPermissions.length > 0 && item.guard.requiredPermissions.find((requiredPermission: string) => permissions[requiredPermission] !== undefined) === undefined) {
      return [];
    }

    if (item.hasChildren()) {
      const filteredChildren: MenuItem[] = [];
      item.children.forEach((c: MenuItem) => {
        filteredChildren.push(...this.filterItem(c, permissions));
      });

      item.children = filteredChildren;
    }

    return [item];
  }

  private computeItems(items: MenuItem[]): MenuItem[] {
    items.forEach((i: MenuItem) => {
      i.buildChildrenPaths();

      if (i.hasChildren()) {
        i.children = this.computeItems(i.children);
      }
    });

    return items;
  }
}
