import { BehaviorSubject, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { IEmploymentModel } from '../../services/http-services.service';
import { EmploymentKeyModel } from '../../models/employment-key-model';

export abstract class BaseEmploymentFilterService {
  private currentEmployments: Observable<IEmploymentModel[]>;
  // Holding state of selected employment
  public selectedEmploymentKeySubject: BehaviorSubject<EmploymentKeyModel> = new BehaviorSubject<EmploymentKeyModel>(undefined);
  private timer: any;

  constructor() { }

  protected abstract getEmploymentsCall(): Observable<IEmploymentModel[]>;

  public getEmployments(): Observable<IEmploymentModel[]> {
    if (!this.currentEmployments) {
      this.currentEmployments = this.getEmploymentsCall().pipe(
        publishReplay(1),
        refCount()
      );
      this.setTimeoutCache();
    }
    return this.currentEmployments;
  }

  private clearCache() {
    this.currentEmployments = null;
  }

  private setTimeoutCache() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.clearCache();
    }, parseInt(environment.requestCacheTimeMs, 10)); // clear cache after requestCacheTimeMs
  }
}
