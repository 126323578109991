import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Language, ClientRole } from './http-services.service';
import { UserModel } from '../models/user-model';
import { RegistrationLoginModel } from '../models/registration-login-model';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private languageKey = 'language';
  private idTokenStorageKey = 'id_token';
  private permissionsKey = 'permissions';
  private userKey = 'user';
  private registrationLoginKey = 'registration_login_key';
  private employerIdKey = 'employer_id';
  private authenticationStateKey = 'authentication_state';
  private registrationIdKey = 'registration_id';
  private registrationTypeKey = 'registration_type';

  constructor() {
    this.initializeLanguage();
  }

  public get language(): Language {
    return localStorage.getItem(this.languageKey) as Language;
  }

  public set language(value: Language) {
    localStorage.setItem(this.languageKey, value);
  }

  public get idToken(): string {
    return localStorage.getItem(this.idTokenStorageKey);
  }

  public set idToken(value: string) {
    localStorage.setItem(this.idTokenStorageKey, value);
  }

  public get userName(): string {
    return this.user.displayName;
  }

  public get userFirstName(): string {
    return this.user.firstName;
  }

  public get userEmail(): string {
    return this.user.emailAddress;
  }

  public get userRole(): ClientRole
  {
    return this.user.clientRole;
  }

  public get permissions(): string[] {
    return JSON.parse(localStorage.getItem(this.permissionsKey));
  }

  public set permissions(value: string[]) {
    localStorage.setItem(this.permissionsKey, JSON.stringify(value));
  }

  public get employerId(): number {
    const item = localStorage.getItem(this.employerIdKey);
    return item ? parseInt(item, 10) : undefined;
  }

  public set employerId(value: number) {
    localStorage.setItem(this.employerIdKey, value ? value.toString() : undefined);
  }

  public get authenticationState(): string {
    return localStorage.getItem(this.authenticationStateKey);
  }

  public set authenticationState(value: string) {
    localStorage.setItem(this.authenticationStateKey, value);
  }

  public get registrationId(): string {
    return localStorage.getItem(this.registrationIdKey);
  }

  public set registrationId(value: string) {
    localStorage.setItem(this.registrationIdKey, value);
  }

  public get registrationType(): string {
    return localStorage.getItem(this.registrationTypeKey);
  }

  public set registrationType(value: string) {
    localStorage.setItem(this.registrationTypeKey, value.toString());
  }

  public setUser(emailAddres: string, firstName: string, displayName: string, clientRole: ClientRole) {
    const user = new UserModel(emailAddres, firstName, displayName, clientRole);
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  public get user(): UserModel {
    const user = localStorage.getItem(this.userKey);
    if (user)
      return plainToClass(UserModel, JSON.parse(user));
    else
      return new UserModel();
  }

  public clearUser(): void {
    localStorage.removeItem(this.userKey);
  }

  public set registrationLogin(registrationLogin: RegistrationLoginModel) {
    localStorage.setItem(this.registrationLoginKey, JSON.stringify(registrationLogin));
  }

  public get registrationLogin(): RegistrationLoginModel | undefined {
    const registrationLogin = localStorage.getItem(this.registrationLoginKey);
    return registrationLogin ? JSON.parse(registrationLogin) : undefined;
  }

  public clearRegistrationLogin(): void {
    localStorage.removeItem(this.registrationLoginKey);
  }

  public clearUserInformation(): void {
    localStorage.removeItem(this.idTokenStorageKey);
    localStorage.removeItem(this.permissionsKey);
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.employerIdKey);
    localStorage.removeItem(this.authenticationStateKey);
    localStorage.removeItem(this.registrationIdKey);
    localStorage.removeItem(this.registrationTypeKey);
    localStorage.removeItem(this.registrationLoginKey);
  }

  private initializeLanguage(): void {
    if (!this.language) {
      // this will be used mostly for testing purposes.
      // in order to be able to change the language at runtime, without making a new deploy
      this.language = environment.language;
    }
  }
}
