import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { TopbarComponent } from './topbar/topbar.component';
import { MenuComponent } from './menu/menu.component';
import { PanelComponent } from './panel/panel.component';
import { PageComponent } from './page/page.component';
import { PageBannerComponent } from './page/page-banner/page-banner.component';

import { PageContentComponent } from './page/page-content/page-content.component';
import { PageContentTopbarComponent } from './page/page-content/page-content-topbar/page-content-topbar.component';
import { PageContentFooterComponent } from './page/page-content/page-content-footer/page-content-footer.component';
import { PageContentSectionComponent } from './page/page-content/page-content-section/page-content-section.component';

import { MenuService } from '../layout/menu/services/menu.service';
import { MenuItemDirective } from '../layout/menu/abstract-components/menu-item.directive';
import { GeneralMessageComponent } from './general-message/general-message.component';

import { DesktopFooterComponent } from '../layout/desktop-footer/desktop-footer.component';
import { DesktopMenuComponent } from '../layout/menu/desktop-menu/desktop-menu.component';
import { PageOverlayComponent } from './page-overlay/page-overlay.component';

const components: any[] = [
  TopbarComponent,
  MenuComponent,
  PageComponent,
  PageOverlayComponent,
  PanelComponent,
  PageBannerComponent,

  PageContentComponent,
  PageContentTopbarComponent,
  PageContentFooterComponent,
  PageContentSectionComponent,

  DesktopFooterComponent,
  DesktopMenuComponent,
  GeneralMessageComponent
];

const directives: any[] = [
    MenuItemDirective,
];

@NgModule({
  declarations: [
    components,
    directives,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    components,
    directives
  ],
  providers: [MenuService]

})
export class LayoutModule { }
