import { Component, OnInit, ViewChild } from '@angular/core';
import { employmentName } from '../../helpers/employment-name.helper';
import { EmploymentKeyModel } from '../../models/employment-key-model';
import { IEmploymentModel, PermissionType } from '../../services/http-services.service';
import { EmploymentFilterService } from './employment-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { MdcMenu } from '../../angular-mdc/menu/menu';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'au-employment-filter',
  templateUrl: './employment-filter.component.html',
  styleUrls: ['./employment-filter.component.scss']
})

export class EmploymentFilterComponent implements OnInit {
  @ViewChild('menu', { static: false }) menu: MdcMenu;
  public canReadClientCompanies = false;
  public employments: { key: EmploymentKeyModel, name: string }[] = [];
  private allEmployersLabel: string;

  constructor(private employmentFilterService: EmploymentFilterService,
    private permissionsService: NgxPermissionsService,
    private translateService: TranslateService) {
    this.allEmployersLabel = this.translateService.instant('EMPLOYMENT_FILTER.ALL_EMPLOYERS');
    this.canReadClientCompanies = this.hasPermission();
  }

  ngOnInit(): void {
    if (this.canReadClientCompanies)
      this.employmentFilterService.getEmployments().subscribe(e => this.dataMapper(e));
  }

  private hasPermission() {
    const permissions = this.permissionsService.getPermissions();
    return permissions[PermissionType[PermissionType.CanReadClientCompanies]] !== undefined;
  }

  private dataMapper(data: IEmploymentModel[]) {
    this.employments = data.map(empl => {
      return {
        key: new EmploymentKeyModel(empl.employerId, empl.id),
        name: employmentName(empl.employerName, empl.departmentName) };
    });

    // If we would add the default employment value without the length check, then it would bypass the length check on the UI
    if (this.employments.length > 1)
      this.employments.unshift({ key: undefined, name: this.allEmployersLabel });
  }

  public setSelectedEmploymentKey(value: EmploymentKeyModel) {
    this.employmentFilterService.selectedEmploymentKeySubject.next(value);
  }

  public get selectedEmploymentKey(): EmploymentKeyModel {
    return this.employmentFilterService.selectedEmploymentKeySubject.getValue();
  }

  public get selectedEmploymentName(): string {
    const foundEmployment = this.employments.find((employment) => employment.key?.equals(this.selectedEmploymentKey));
    return foundEmployment ? foundEmployment.name : this.allEmployersLabel;
  }

  public changeMenuOpenState() {
    this.menu.open = !this.menu.open
  }
}
