import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from '../../../../../general-information/models/subject';
import { ContentService } from '../../../../services/content.service';
import { Language } from '../../../../services/http-services.service';
import { map } from 'rxjs/operators';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'au-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],  
  encapsulation: ViewEncapsulation.None // Required for styling of the html that is loaded through an Http call
})
export class AccordionItemComponent implements OnInit {
  @Input() public subject: Subject;
  public content = '';

  constructor(private contentService: ContentService,
    private storageService: StorageService) { }

  ngOnInit() {
    if (this.subject.expanded) {
      this.loadContent().subscribe(content => this.content = content);
    }
  }

  public itemClick() {
    this.subject.expanded = !this.subject.expanded;

    if (this.subject.expanded && !this.content) {
      this.loadContent().subscribe(content => this.content = content);
    }
  }

  public loadContent() {
    return this.contentService.getContent(this.subject.contentUrl, this.storageService.language)
      .pipe(
        map(val => val.content)
      );
  }
}
