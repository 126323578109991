import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routePathName } from './shared/constants/route-pathname.constant';

import { GeneralMessageComponent } from './layout/general-message/general-message.component';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { VersionComponent } from './version/version.component';

import { TestComponent } from './test/test.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  // -------------------------------------------------------------------------------------------- TEST COMPONENTEN - BEGIN
  {
    path: 'test',
    component: TestComponent,
    pathMatch: 'full'
  },
  // -------------------------------------------------------------------------------------------- /TEST COMPONENTEN - END
  {
    path: 'version',
    component: VersionComponent,
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard],
    canActivateChild: [AuthenticatedGuard]
  },
  {
    path: 'general-information',
    component: GeneralInformationComponent,
    pathMatch: 'full'
  },
  {
    path: routePathName.registration,
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: routePathName.employee,
    children: [
      {
        path: '',
        redirectTo: routePathName.overview,
        pathMatch: 'full'
      },
      {
        path: routePathName.overview,
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule),
        canLoad: [AuthenticatedGuard]
      },
      {
        path: routePathName.appointments,
        loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule),
        canLoad: [AuthenticatedGuard]
      },
      {
        path: routePathName.authorizations,
        loadChildren: () => import('./authorizations/authorizations.module').then(m => m.AuthorizationsModule),
        canLoad: [AuthenticatedGuard]
      },
      {
        path: routePathName.generalInformation,
        loadChildren: () => import('./general-information/general-information.module').then(m => m.GeneralInformationModule),
        canLoad: [AuthenticatedGuard]
      },
      {
        path: routePathName.profile,
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canLoad: [AuthenticatedGuard]
      }
    ],
    canActivate: [AuthenticatedGuard],
    canActivateChild: [AuthenticatedGuard]
  },
  {
    path: routePathName.error,
    component: GeneralMessageComponent,
    data: {
      titleTranslationKey: 'ERROR.TITLE',
      messageTranslationKey: 'ERROR.CONTENT'
    }
  },
  {
    path: routePathName.maintenance,
    component: GeneralMessageComponent,
    data: {
      titleTranslationKey: 'MAINTENANCE.TITLE',
      messageTranslationKey: 'MAINTENANCE.CONTENT'
    }
  },
  {
    path: '**',
    component: GeneralMessageComponent,
    data: {
      titleTranslationKey: 'NOT_FOUND.TITLE',
      messageTranslationKey: 'NOT_FOUND.CONTENT'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],  // , { enableTracing: true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
