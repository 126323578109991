import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

@Component({
  selector: 'au-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() controlName: string;
  @Input() label: string;
  @Input() accept: string;
  public form: FormGroup;
  private control: AbstractControl;
  public resultsForm = this.fb.group({
    fileResult: ['']
  });

  constructor(public fb : FormBuilder,
    public controlContainer: ControlContainer) {
  }

  ngOnInit() {
    this.form = (this.controlContainer.control as FormGroup);
    this.control = this.form.get(this.controlName);
    this.control.valueChanges.subscribe((file: File) => {
      if (file) {
        this.resultsForm.get('fileResult').setValue(file.name);
      }
      else {
        this.resultsForm.get('fileResult').setValue('');
      }
    });

    this.control.statusChanges.subscribe((status) => {
      if (status.toUpperCase() === 'INVALID')
        this.resultsForm.get('fileResult').setErrors(this.control.errors);
      else if (status.toUpperCase() === 'VALID')
        this.resultsForm.get('fileResult').setErrors(null);
    });
  }

  public onClick() {
    this.clickFileInput();
  }

  public clickFileInput() {
    this.fileInput.nativeElement.click();
  }
}
