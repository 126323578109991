import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount, map, count } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

import { NgxPermissionsService } from 'ngx-permissions';
import { AppointmentClientService, AppointmentItemModel, AppointmentModel, ArboUnieOfficeModel, EmployerClientService, IAppointmentModel, PermissionType } from '../../shared/services/http-services.service';
import { StorageService } from '../../shared/services/storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private upcomingAppointments: Observable<AppointmentItemModel[]>;
  private timer: any;

  constructor(
    private permissionsService: NgxPermissionsService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private appointmentClientService: AppointmentClientService,
    private employerClientService: EmployerClientService
  ) { }

  public canReadAppointments(): boolean {
    const permissions = this.permissionsService.getPermissions();
    return permissions[PermissionType[PermissionType.CanReadClientAppointments]] !== undefined || permissions[PermissionType[PermissionType.CanReadClientAppointmentsAO]] !== undefined;
  }

  public getAppointment(appointmentId: number): Observable<AppointmentModel> {
    return this.appointmentClientService.getAppointment(appointmentId);
  }

  public getArboUnieOffice(employerId: number): Observable<ArboUnieOfficeModel> {
    return this.employerClientService.getArboUnieOffice(employerId);
  }

  public hasUpcomingAppointments(): Observable<boolean> {
    return this.getUpcomingAppointments().pipe(
      count(i => i !== undefined && i.length > 0)
    ).pipe(
      map(val => {
        return val > 0
      })
    );
  }

  public getUpcomingAppointments(): Observable<AppointmentItemModel[]> {
    if (!this.upcomingAppointments) {
      this.upcomingAppointments = this.appointmentClientService.getUpcomingAppointments().pipe(
          publishReplay(1),
          refCount()
        );
      this.setTimeoutCache();
    }
    return this.upcomingAppointments;
  }

  public get greeting() {
    const firstName = this.storageService.userFirstName;

    if (firstName) {
      return this.translateService.instant('APPOINTMENT.GREETING_NAME', { name: firstName });
    }
    else {
      return this.translateService.instant('APPOINTMENT.GREETING');
    }
  }

  public getAppointmentTime(seconds: number): string {
    return seconds ?
      dayjs.utc().startOf('day')
      .add(seconds, 'second')
      .format('HH:mm')
      : '';
  }

  public getAppointmentLocation(appointment: IAppointmentModel): string {
    return `${appointment.locationDescription} (${appointment.locationStreet} ${appointment.locationNumber}${appointment.locationNrAddition}, ${appointment.locationCity})`;
  }

  private clearCache() {
    this.upcomingAppointments = undefined;
  }

  private setTimeoutCache() {
    if (this.timer) {
        clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.clearCache();
    }, parseInt(environment.requestCacheTimeMs, 10)); // clear cache after requestCacheTimeMs
  }
}
