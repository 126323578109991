import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, NavigationExtras } from '@angular/router';
import { filter } from 'rxjs/operators';
import { routePathName } from '../constants/route-pathname.constant';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];

  private landingRoute: string = routePathName.landing;
  private homeRoute: string = routePathName.employee;
  private generalInformationRoute = `${routePathName.employee}/${routePathName.generalInformation}`;
  private unauthorizedRoute: string = routePathName.notAuthorized;
  private error: string = routePathName.error;

  public shouldBackButtonBeVisible = true;
  public backButtonVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router, private location: Location) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {

        // do not show back button when the user clicks on the same menu item more than one time
        if (urlAfterRedirects !== this.history[this.history.length - 1]) {
          this.showBackButton();
        }

        this.history.push(urlAfterRedirects);
      });
  }

  public setUrl(url: string) {
    window.location.href = url;    
  }

  public goBack(): void {
    this.location.back();
  }

  public goTo(route: string, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([route], extras);
  }

  public goToLanding(): void {
    this.goTo(this.landingRoute);
  }

  public goToMaintenance(): void {
    this.goTo(routePathName.maintenance);
  }

  public goToHome(): Promise<boolean> {
    return this.goTo(this.homeRoute);
  }

  public goToGeneralInformationSubject(subject?: string): Promise<boolean> {
    if (subject)
      return this.goTo(this.generalInformationRoute, { fragment: subject });
    else
      return this.goTo(this.generalInformationRoute);
  }

  public goToUnauthorized(): void {
    this.goToWithoutChangingLocation(this.unauthorizedRoute);
  }

  public goToError(): void {
    this.goToWithoutChangingLocation(this.error);
  }

  public refreshCurrentRoute(): void {
    this.refreshRoute([this.currentRoute]);
  }

  public get isPreviousRouteChildOfCurrentRoute(): boolean {
    return this.previousRoute.startsWith(this.currentRoute);
  }

  public get previousRoute(): string {
    // always the last item in the array is the current url
    const previousUrlStep: number = 2;

    return this.history[this.history.length - previousUrlStep] || `/${this.homeRoute}`;
  }

  public get currentRoute(): string {
    return this.history[this.history.length - 1] || `/${this.homeRoute}`;
  }

  public showBackButton(): void {
    this.updateBackButtonVisibility(true);
  }

  public hideBackButton(): void {
    this.updateBackButtonVisibility(false);
  }

  private updateBackButtonVisibility(isVisible: boolean): void {
    this.shouldBackButtonBeVisible = isVisible;
    this.backButtonVisibility.emit(isVisible);
  }

  private refreshRoute(route: any[]): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(route));
  }

  private goToWithoutChangingLocation(route: string): Promise<boolean> {
    return this.router.navigate([route], { skipLocationChange: true });
  }
}
