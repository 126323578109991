import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'au-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() disabled = false;
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

  public onClick(event: MouseEvent) {
    event.stopPropagation();
    this.click.emit(event);
  }
}
