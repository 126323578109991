import { Injectable } from '@angular/core';
import { ExistingRelationModel } from '../shared/services/http-services.service';
import { StorageService } from '../shared/services/storage.service';

export enum RegistrationType {
    Employee = 0,
    Employer = 1
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationStateService {
  public newAccountCreated: boolean;
  public emailAddress: string;
  public existingRelation: boolean;
  public isAlreadyLinked: boolean;
  public existingRelationInformation: ExistingRelationModel;

  constructor(private storageService: StorageService) { }

  public get type(): RegistrationType {
    return RegistrationType[this.storageService.registrationType];
  }

  public set type(value: RegistrationType) {
    this.storageService.registrationType = RegistrationType[value];
  }

  public get key(): string {
    return this.storageService.registrationId;
  }

  public set key(value: string) {
    this.storageService.registrationId = value;
  }

  public storeSignUpData(type: string, key: string, newAccountCreated: boolean, emailAddress: string) {
    this.type = RegistrationType[type],
    this.key = key;
    this.newAccountCreated = newAccountCreated;
    this.emailAddress = emailAddress;
  }

  public canRegister(): boolean {
    return this.key !== undefined && this.type !== undefined;
  }

  public get isLoggedInAsNonExistingRelationWithEmployerLink(): boolean {
    const registrationLogin = this.storageService.registrationLogin;
    return registrationLogin && registrationLogin.noExistingRelation;
  }
}
