<mdc-top-app-bar class="topbar" fixed>
    <mdc-top-app-bar-row>
      <mdc-top-app-bar-section align="start">
        <mdc-icon class="topbar__logo">
          <img (click)="redirectFromLogo()" src="/assets/images/logo.svg" height="32" />
        </mdc-icon>
      </mdc-top-app-bar-section>
      <mdc-top-app-bar-section align="end">
        <button class="topbar__menu" mdcTopAppBarNavIcon (click)="layoutService.setToggleMenu()">
          <mdc-icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <rect x="3" y="4" width="18" height="2"></rect>
              <rect x="3" y="11" width="18" height="2"></rect>
              <rect x="3" y="18" width="18" height="2"></rect>
            </svg>
          </mdc-icon>
        </button>
      </mdc-top-app-bar-section>
    </mdc-top-app-bar-row>
</mdc-top-app-bar>
