import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'au-page-content-footer',
  templateUrl: './page-content-footer.component.html',
  styleUrls: ['./page-content-footer.component.scss']
})
export class PageContentFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
