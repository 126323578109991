import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from '../authorization/unauthorized/unauthorized.component';
import { AuthenticatedGuard } from '../shared/guards/authenticated.guard';
import { routePathName } from '../shared/constants/route-pathname.constant';

const routes: Routes = [
    {
        path: routePathName.notAuthorized,
        component: UnauthorizedComponent,
        canActivate: [AuthenticatedGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthorizationRoutingModule {
}
