import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'au-general-message',
    templateUrl: './general-message.component.html',
    styleUrls: ['./general-message.component.scss']
})
export class GeneralMessageComponent {

    constructor(private route: ActivatedRoute) {
    }

    public get titleTranslationKey(): string {
        return this.route.snapshot.data.titleTranslationKey;
    }

    public get messageTranslationKey(): string {
        return this.route.snapshot.data.messageTranslationKey;
    }
}
