import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';

@Component({
  selector: 'au-page-overlay',
  templateUrl: './page-overlay.component.html',
  styleUrls: ['./page-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageOverlayComponent implements OnInit, OnDestroy {
  @Input()
  public title: string;

  @Output()
  public closeOverlay: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    document.body.classList.add('overlay');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
  }

  public close(): void {
    this.closeOverlay.emit();
  }
}
